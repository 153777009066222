var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"pointer",attrs:{"items":_vm.pages,"headers":_vm.headers,"items-per-page":-1,"hide-default-footer":"","no-data-text":"No custom pages","group-by":"folder"},on:{"click:row":function($event){return _vm.editCustomPage($event)}},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var toggle = ref.toggle;
return [_c('td',{staticClass:"text-left",attrs:{"colspan":"6"},on:{"click":toggle}},[_c('div',{staticClass:"row-format align-center gap-2"},[_c('v-icon',{attrs:{"size":"20","color":"secondary"}},[_vm._v("folder")]),_vm._v(_vm._s(group ? group : ' -- ')+" ")],1)])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"row-format align-center gap-1"},[_c('v-icon',{staticClass:"material-symbols-rounded",attrs:{"size":"20"}},[_vm._v(_vm._s(item.icon))]),_c('div',[_vm._v(_vm._s(item.title))])],1)]}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.enabled ? 'Yes' : 'No')+" ")]}},{key:"item.isDefault",fn:function(ref){
var item = ref.item;
return [(item.type === 'Internal' || item.type === 'iFrame')?_c('span',[_vm._v(_vm._s(item.isDefault ? 'Yes' : 'No'))]):_c('span',[_vm._v(" -- ")])]}},{key:"item.sortOrder",fn:function(ref){
var item = ref.item;
return [(item.isDefault)?_c('div',[_vm._v("--")]):_c('div',{staticStyle:{"max-width":"100px"},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-select',{staticStyle:{"margin-top":"-5px"},attrs:{"items":_vm.navPositions,"hide-details":"","dense":"","item-value":"value","item-text":"label"},on:{"change":function($event){return _vm.handleNavPositionChange(item)}},model:{value:(item.sortOrder),callback:function ($$v) {_vm.$set(item, "sortOrder", $$v)},expression:"item.sortOrder"}})],1)]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.pageTypes.find(function (t) { return t.value === item.type; }).text)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"row-format"},[_c('v-icon',{staticClass:"ml-auto mr-1",attrs:{"size":20,"color":"gray_70"},on:{"click":function($event){$event.stopPropagation();return _vm.confirmDeleteCustomPage(item)}}},[_vm._v("$delete")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }