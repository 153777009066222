<template>
	<div class="pb-8">
		<page-header
			class="pb-3"
			:compact="false"
			:bread-crumbs="[
				{ to: '/home', label: 'Home' },
				{ label: 'Client management' },
				{ to: '/clients/overview', label: 'Client list' },
			]"
		>
			{{ client.name }}
		</page-header>

		<div id="client-detail-view" v-if="isReady && filtersReady">
			<div id="detail-wrapper" class="align-center" v-if="isReady">
				<div class="row-format">
					<div class="row-format gap-3" style="flex-wrap: wrap">
						<div
							:class="`page-link ${currentView === page.value ? 'page-link-active' : ''}`"
							v-for="page in pages"
							:key="page.value"
							@click.stop="setView(page.value, $event)"
						>
							<div>{{ page.label }}</div>
						</div>
					</div>

					<div class="ml-auto">
						<div class="row-format align-center">
							<v-menu
								v-if="currentView === 'Overview'"
								:nudge-bottom="0"
								:elevation="0"
								content-class="add-new-menu"
								bottom
								left
								rounded
								offset-overflow
								offset-y
								transition="slide-y-transition"
							>
								<template v-slot:activator="{ on }">
									<v-btn class="super-action" v-on="on"><v-icon size="20">add</v-icon> Add</v-btn>
								</template>
								<div class="add-new-dropdown">
									<div
										v-for="(item, index) in addNew"
										:key="index"
										@click="handleAddNew(item.value)"
										class="add-new-item"
									>
										{{ item.label }}
									</div>
								</div>
							</v-menu>
							<v-btn v-else-if="currentView !== 'Meetings'" class="super-action" @click="handleAddNewFromTab"
								><v-icon size="20">add</v-icon> Add</v-btn
							>
						</div>
					</div>
				</div>

				<div class="body-content mt-6 column-format">
					<overview
						:client="client"
						:projects="projects"
						v-if="currentView === 'Overview'"
						:is-active="currentView === 'Overview'"
						@update-client="updateClient"
						@edit-client="editClient"
						:filter="filters['Overview']"
						:currencies="currencies"
						@unlink-accounting="unlinkAccounting()"
						@time-clicked="setView('Time')"
						@money-clicked="setView('Invoices')"
						@send-email="sendEmail($event)"
					></overview>

					<notes
						ref="Notes"
						:client="client"
						v-if="currentView === 'Notes'"
						:is-active="currentView === 'Notes'"
						:filter="filters['Notes']"
					>
						<template v-slot:filter>
							<client-filter
								:key="refreshkey"
								class="mr-4"
								v-model="filters[currentView]"
								:current-view="currentView"
								@clear-filter="clearFilter(currentView)"
								:projects="projects.filter((p) => p.active)"
							></client-filter>
						</template>
					</notes>

					<invoice-list
						ref="Invoices"
						:client="client"
						v-if="currentView === 'Invoices'"
						:is-active="currentView === 'Invoices'"
						:filter="filters['Invoices']"
						:currencies="currencies"
					>
						<template v-slot:filter>
							<client-filter
								:key="refreshkey"
								class="mr-4"
								v-model="filters[currentView]"
								:current-view="currentView"
								@clear-filter="clearFilter(currentView)"
								:projects="projects.filter((p) => p.active)"
							></client-filter>
						</template>
					</invoice-list>

					<deposit-list
						ref="Deposits"
						:client="client"
						v-if="currentView === 'Deposits'"
						:is-active="currentView === 'Deposits'"
						:filter="filters['Deposits']"
						:currencies="currencies"
					>
						<template v-slot:filter>
							<client-filter
								:key="refreshkey"
								class="mr-4"
								v-model="filters[currentView]"
								:current-view="currentView"
								@clear-filter="clearFilter(currentView)"
								:projects="projects.filter((p) => p.active)"
							></client-filter>
						</template>
					</deposit-list>

					<timer-event-list
						ref="Time"
						:client="client"
						v-if="currentView === 'Time'"
						:is-active="currentView === 'Time'"
						:filter="filters['Time']"
						@quick-invoice="$refs.Invoices.addNewQuick()"
						@open-invoice="$refs.Invoices.openInvoice($event)"
					>
						<template v-slot:filter>
							<client-filter
								:key="refreshkey"
								class="mr-4"
								v-model="filters[currentView]"
								:current-view="currentView"
								@clear-filter="clearFilter(currentView)"
								:projects="projects.filter((p) => p.active)"
							></client-filter>
						</template>
					</timer-event-list>

					<proposal-list
						ref="Agreements"
						:client="client"
						v-if="currentView === 'Agreements'"
						:is-active="currentView === 'Agreements'"
						:filter="filters['Agreements']"
						:proposal-id="proposalId"
						@newProjectCreated="newProjectCreated()"
					>
						<template v-slot:filter>
							<client-filter
								:key="refreshkey"
								class="mr-4"
								v-model="filters[currentView]"
								:current-view="currentView"
								@clear-filter="clearFilter(currentView)"
								:projects="projects.filter((p) => p.active)"
							></client-filter>
						</template>
					</proposal-list>

					<forms-list
						ref="Forms"
						:client="client"
						v-if="currentView === 'Forms'"
						:is-active="currentView === 'Forms'"
						:filter="filters['Forms']"
					>
						<template v-slot:filter>
							<client-filter
								:key="refreshkey"
								class="mr-4"
								v-model="filters[currentView]"
								:current-view="currentView"
								@clear-filter="clearFilter(currentView)"
								:projects="projects.filter((p) => p.active)"
							></client-filter>
						</template>
					</forms-list>

					<inbox
						ref="Inbox"
						:email-boxes="$store.state.emailBoxes"
						:subscription="$store.state.communicatorSubscription"
						v-if="currentView === 'Inbox'"
						:is-active="currentView === 'Inbox'"
						:client-id="client.id"
					>
					</inbox>

					<ticket-view
						ref="Tickets"
						v-if="currentView === 'Tickets'"
						:is-active="currentView === 'Tickets'"
						:client-id="client.id"
					>
					</ticket-view>

					<meeting-list
						ref="Meetings"
						:client="client"
						v-if="currentView === 'Meetings'"
						:is-active="currentView === 'Meetings'"
						:filter="filters['Meetings']"
					>
						<template v-slot:filter>
							<client-filter
								:key="refreshkey"
								class="mr-4"
								v-model="filters[currentView]"
								:current-view="currentView"
								@clear-filter="clearFilter(currentView)"
								:projects="projects.filter((p) => p.active)"
							></client-filter>
						</template>
					</meeting-list>

					<client-opportunities
						ref="Opportunities"
						:client="client"
						v-show="currentView === 'Opportunities'"
						:is-active="currentView === 'Opportunities'"
						:filter="filters['Opportunities']"
					>
						<template v-slot:filter>
							<client-filter
								:key="refreshkey"
								class="mr-4"
								v-model="filters[currentView]"
								:current-view="currentView"
								@clear-filter="clearFilter(currentView)"
								:projects="projects.filter((p) => p.active)"
							></client-filter>
						</template>
					</client-opportunities>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ClientService from './ClientService';
	import ProjectService from '@/modules/projects/ProjectService';
	import jsonPatch from 'fast-json-patch';
	import ClientEdit from './detail/ClientEdit';
	import InvoiceList from '@/modules/clients/detail/invoices/InvoiceList';
	import TimerEventList from '@/modules/clients/detail/timetracking/TimerEventList';
	import DepositList from '@/modules/clients/detail/invoices/DepositList';
	import ProposalList from '@/modules/clients/detail/proposals/ProposalList';
	import Overview from '@/modules/clients/detail/overview/Overview';
	import FormsList from '@/modules/clients/detail/forms/FormsList';
	import MeetingList from '@/modules/clients/detail/meetings/MeetingList';
	import Inbox from '@/modules/communicator/inbox/Inbox';
	import ClientFilter from '@/modules/clients/detail/filter/ClientFilter';
	import NewProject from '@/modules/projects/NewProject';
	import ClientOpportunities from '@/modules/clients/detail/opportunities/ClientOpportunities';
	import TicketView from '@/modules/communicator/inbox/TicketView';
	import Notes from '@/modules/clients/detail/notes/ClientNotes.vue';
	import PageHeader from '@/components/PageHeader';
	import NewEmail from '@/modules/communicator/inbox/email/NewEmail';

	export default {
		name: 'clientDetail',
		components: {
			TicketView,
			ClientOpportunities,
			Inbox,
			MeetingList,
			FormsList,
			DepositList,
			TimerEventList,
			InvoiceList,
			ProposalList,
			Overview,
			ClientFilter,
			Notes,
			PageHeader,
		},

		props: ['id', 'focus', 'v', 'proposalId', 'projectId'],

		data() {
			return {
				isReady: false,
				filtersReady: false,
				client: {
					id: '',
					name: '',
					address1: '',
					address2: '',
					city: '',
					state: '',
					zip: '',
					phone: '',
					website: '',
					contacts: [],
					color: '',
				},
				projects: [],
				contactId: 0,
				observer: null,
				clientService: new ClientService(),
				projectService: new ProjectService(),
				editClientDialog: false,
				// deleteClientDialog: false,
				editContactDialog: false,
				deleteContactDialog: false,
				currentView: 'Overview',
				refreshkey: 0,

				filters: {
					Overview: {},
					Projects: {},
					Invoices: {},
					Deposits: {},
					Time: {},
					Agreements: {},
					Meetings: {},
					Forms: {},
					Notes: {},
					Opportunities: {},
				},

				projectListKey: 0,
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
			this.$store.state.eventBus.$on('client_onboarding_create', this.handleOnBoardingRequest);
			this.$store.state.eventBus.$on('project_onboarding_tasks', this.handleOnBoardingProjectTasks);
			this.loadFilters();
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
			this.$store.state.eventBus.$off('client_onboarding_create', this.handleOnBoardingRequest);
			this.$store.state.eventBus.$off('project_onboarding_tasks', this.handleOnBoardingProjectTasks);
			this.$store.commit('clearNavDetailKey', { key: 'clientName' });
		},

		methods: {
			handleOnBoardingRequest: function(type) {
				this.handleAddNew(type);
			},

			handleOnBoardingProjectTasks: function() {
				if (this.projects.length) {
					let id = this.projects[0].id;
					this.$router.push(`/project/${id}?v=Tasks&autoCreate=true`);
				}
			},

			unlinkAccounting: function() {
				this.clientService.resetAccountingIntegrationKeys(this.client.id).then((res) => {
					this.client = res.data;
					this.$store.commit('success', 'Successfully reset relationship between client and accounting system.');
				});
			},

			sendEmail: function(email) {
				let toList = [email];
				this.$store.state.globalModalController
					.openModal(NewEmail, { toList: toList, clientId: this.client.id }, true, true, false, true)
					.then(() => {});
			},

			loadFilters: function() {
				let storedFilters = localStorage.getItem('CLIENT_DETAIL_FILTERS');
				if (storedFilters) {
					this.filters = JSON.parse(storedFilters);
				} else {
					this.initFilter();
				}

				let initList = ['Overview','Projects','Invoices','Deposits','Time','Agreements','Meetings','Forms','Notes','Opportunities']

				for(let i=0; i < initList.length; i++){
					let key = initList[i];
					if (!Object.prototype.hasOwnProperty.call(this.filters, key)) {
						this.clearFilter(key, true);
					}
				}

				this.filtersReady = true;
			},

			saveFilters: function() {
				if (this.filters) {
					localStorage.setItem('CLIENT_DETAIL_FILTERS', JSON.stringify(this.filters));
				}
			},

			initFilter: function() {
				this.clearFilter('Overview', true);
				this.clearFilter('Projects', true);
				this.clearFilter('Invoices', true);
				this.clearFilter('Deposits', true);
				this.clearFilter('Time', true);
				this.clearFilter('Agreements', true);
				this.clearFilter('Forms', true);
				this.clearFilter('Meetings', true);
				this.clearFilter('Opportunities', true);
				this.clearFilter('Notes', true);
			},

			clearFilter: function(key, init = false) {
				this.filters[key] = this.emptyFilter(key, init);
				this.refreshkey++;
				if (!init) {
					this.filters = JSON.parse(JSON.stringify(this.filters));
				}
			},

			emptyFilter: function(key, init) {
				let result = {
					search: null,
					dateSpecifier: 'past-90-days',
					earliest: null,
					latest: null,
					projectList: [],
					projectStatus: [],
					invoiceStatus: [],
					depositType: [],
					timeEntryStatus: [],
					proposalStatus: [],
					formStatus: [],
					clients: [],
				};

				if (key === 'Projects' && init) {
					result.projectStatus.push(true);
				}

				return result;
			},

			handleAccountChange: function() {
				this.$router.push('/clients');
			},

			setView: function(view, event) {
				this.$track.record('page-view', { module: `client-${view.toLowerCase()}` });

				if (event && (event.ctrlKey || event.metaKey)) {
					window.open(this.$router.currentRoute.path + `?v=${view}`, '_blank');
				} else {
					this.currentView = view;
					window.history.pushState({}, document.title, this.$router.currentRoute.path + `?v=${view}`);
				}
			},

			newProjectCreated() {
				this.setView('Projects');
				this.projectListKey++;
			},

			getProjects: function() {
				if (this.$store.getters.hasFeatureAccess('projects')) {
					this.projectService
						.getProjectsByClient(this.id, true)
						.then((res) => {
							this.projects.splice(0, this.projects.length);
							this.projects.push(...res.data);
						})
						.catch((err) => {
							this.$store.commit('error', err.response.data.message);
						});
				}
			},

			handleAddNewFromTab: function() {
				this.$refs[this.currentView].addNew(this.client.id);
			},

			handleAddNew: function(type) {
				if (type === 'Projects') {
					let binding = {
						client: this.client,
					};
					this.$store.state.globalModalController.openModal(NewProject, binding).then((res) => {
						if (res) {
							setTimeout(() => this.$router.push(`/project/${res.id}?edit=true`), 500);
						}
					});
				} else {
					this.currentView = type;
					this.$nextTick(() => this.$refs[type].addNew(this.client.id));
				}
			},

			getClientDetail() {
				this.isReady = false;
				this.clientService
					.getClientDetail(this.id)
					.then((res) => {
						this.client = res.data;
						this.observer = jsonPatch.observe(this.client);
						this.isReady = true;
						this.$store.commit('setNavDetailKey', { key: 'clientName', value: this.client.name });
					})
					.catch((err) => {
						console.log(err.response);
						if (err.response && err.response.data.status === 404) {
							this.alert('error', 'Client not found.');
							return;
						}
						this.alert('error', err.response.data.message);
					});
			},

			editClient: function() {
				let binding = {
					client: this.client,
				};
				this.$store.state.globalModalController.openModal(ClientEdit, binding).then((res) => {
					if (res) {
						if (res._update) {
							this.updateClient(res);
						} else if (res._delete) {
							this.deleteClient();
						}
					}
				});
			},

			updateClient(client, doAlert = true, deleteMsg = false) {
				this.clientService
					.putClient(client.id, client)
					.then((res) => {
						this.client = res.data;
						this.$store.commit('setNavDetailKey', { key: 'clientName', value: this.client.name });
						if (doAlert) {
							if (deleteMsg) {
								this.alert('success', this.$t('global.deleted-successfully'));
							} else {
								this.alert('success', this.$t('global.saved-successfully'));
							}
						}
					})
					.catch((err) => {
						this.alert('error', err.response.data.message);
					});
			},

			deleteClient() {
				this.$store.commit('startLoading');
				this.clientService
					.deleteClient(this.id)
					.then(() => {
						this.alert('success', 'Deleted');
						this.$router.push('/clients');
					})
					.catch((err) => {
						this.alert('error', err.response.data.message);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			sortContactAlpha(a, b) {
				let aLast = a.lastName ?? '';
				let bLast = b.lastName ?? '';
				if (aLast.toLowerCase() < bLast.toLowerCase()) {
					return -1;
				}
				if (aLast.toLowerCase() > bLast.toLowerCase()) {
					return 1;
				}
				return 0;
			},

			alert(type, message) {
				this.$store.commit(type, message);
			},
		},

		computed: {
			pages: function() {
				let result = [];
				result.push({ label: 'Overview', value: 'Overview' });
				result.push({ label: 'Notes', value: 'Notes' });
				if (this.$store.getters.hasInvoiceAccess) result.push({ label: 'Invoices', value: 'Invoices' });
				if (this.$store.getters.hasInvoiceAccess) result.push({ label: 'Deposits', value: 'Deposits' });
				result.push({ label: 'Inbox', value: 'Inbox' });
				if (this.$store.getters.hasFeatureAccess('tickets')) result.push({ label: 'Tickets', value: 'Tickets' });
				result.push({ label: 'Timesheets', value: 'Time' });
				if (this.$store.getters.hasFeatureAccess('agreements')) result.push({ label: 'Agreements', value: 'Agreements' });
				result.push({ label: 'Forms', value: 'Forms' });
				result.push({ label: 'Meetings', value: 'Meetings' });
				if (this.$store.getters.hasFeatureAccess('pipeline'))
					result.push({ label: 'Opportunities', value: 'Opportunities' });
				return result;
			},

			addNew: function() {
				let result = [];
				if (this.$store.getters.hasFeatureAccess('agreements')) result.push({ label: 'Agreement', value: 'Agreements' });
				if (this.$store.getters.hasInvoiceAccess) result.push({ label: 'Deposit', value: 'Deposits' });
				result.push({ label: 'Email', value: 'Inbox' });
				result.push({ label: 'Form', value: 'Forms' });
				if (this.$store.getters.hasInvoiceAccess) result.push({ label: 'Invoice', value: 'Invoices' });
				result.push({ label: 'Note', value: 'Notes' });
				if (this.$store.getters.hasFeatureAccess('pipeline'))
					result.push({ label: 'Opportunity', value: 'Opportunities' });
				if (this.$store.getters.hasFeatureAccess('projects')) result.push({ label: 'Project', value: 'Projects' });
				if (this.$store.getters.hasFeatureAccess('tickets')) result.push({ label: 'Ticket', value: 'Tickets' });
				result.push({ label: 'Time Entry', value: 'Time' });
				return result;
			},

			currencies: function() {
				let result = [];

				if (this.client.currency && this.client.currency !== this.$store.state.defaultCurrency) {
					result.push(this.client.currency);
				}

				result.push(this.$store.state.defaultCurrency);

				return result;
			},

			sampleMode() {
				return this.$store.getters.isSampleMode;
			},
		},

		watch: {
			currentView: function(newVal, oldVal) {
				if (newVal === 'Overview' && oldVal !== 'Overview') {
					this.getProjects();
				}
			},
			filters: {
				deep: true,
				handler() {
					this.saveFilters();
				},
			},
			v: function(val) {
				if (val) {
					this.currentView = val;
				} else {
					this.currentView = 'Overview';
				}
			},
			id: {
				immediate: true,
				handler() {
					// Moved this from mounted() to make it so it reloads if URL changes
					this.$track.record('page-view', { module: 'client-detail' });
					this.getClientDetail();
					this.getProjects();

					if (this.v) {
						this.currentView = this.v;
					}
				},
			},
		},
	};
</script>

<style lang="scss" scoped>
	#client-detail-view {
		//background-color: var(--v-white-base);
		//border: 1px solid var(--v-gray_30-base);
		//border-radius: 4px;
	}

	.archived-chip {
		background-color: var(--v-warning_10-base);
		color: var(--v-warning-base);
		border-radius: 4px;
		font-size: 12px;
		padding: 2px 8px;
	}

	.body-content {
	}
</style>
