<template>
	<div class="column-format" v-if="initialized">
		<div
			class="column-format centered fill-height"
			v-if="!proposalList.length && !agreementList.length"
			style="min-height: calc(100vh - 300px)"
		>
			<empty-view
				header="No agreements yet"
				body="Moxie agreements are beautiful and easy to use.  They provide integrated contracts, e-signatures, payments, and deliverables."
				cta="Create an agreement"
				video-header="See how it works"
				video-body="Learn how to create proposals, quotes, and contracts that keep you safe and your client happy."
				video-cta="Watch the tutorial"
				video-id="5iEIkj2r4Gg"
				@cta-clicked="addNew()"
			></empty-view>
		</div>

		<div v-if="(proposalList.length || agreementList.length) && !filteredProposalList.length" class="mt-6">
			<empty-filtered-results></empty-filtered-results>
		</div>

		<div>
			<slot name="filter"></slot>

			<v-data-table
				v-if="filteredProposalList.length"
				:items="filteredProposalList"
				:headers="headers"
				class="pointer mt-4"
				:hide-default-footer="true"
				:items-per-page="-1"
				@click:row="openAgreement"
			>
				<template v-slot:item.client="{ item }">
					<div class="row-format align-center">
						<client-avatar :client="item.client" :small="true"></client-avatar>
						<div class="ml-n2">{{ item.client.name }}</div>
					</div>
				</template>
				<template v-slot:item.date="{ item }">
					{{ DateTime.fromISO(item.date).toLocaleString(DateTime.DATE_MED) }}
				</template>
				<template v-slot:item.status="{ item }">
					<div v-html="formatStatus(item.status)"></div>
				</template>
				<template v-slot:item.version="{ item }"> v{{ item.version }} </template>
				<template v-slot:item.lastEvent="{ item }">
					<div v-if="item.lastEvent">
						{{ item.lastEvent.event }} -
						{{ DateTime.fromISO(item.lastEvent.timestamp).toLocaleString(DateTime.DATETIME_MED) }}
					</div>
					<div v-else>--</div>
				</template>
				<template v-slot:item.action="{ item }">
					<v-menu close-on-content-click close-on-click :nudge-bottom="30">
						<template v-slot:activator="scope">
							<div class="ml-1">
								<v-btn icon class="menu-activator" v-on="scope.on">
									<v-icon :size="20">{{ scope.value ? '$arrowUp' : '$moreHorizontal' }}</v-icon>
								</v-btn>
							</div>
						</template>
						<div class="more-menu">
							<div class="more-menu-item" @click="openAgreement(item)">Edit</div>
							<div class="more-menu-item" @click="duplicateAgreement(item)" v-if="item.version === 2 && client">
								Duplicate
							</div>
							<div class="more-menu-item" @click="saveAsTemplate(item)">Save as template</div>
							<div class="more-menu-item" @click="confirmDelete(item)">Delete</div>
						</div>
					</v-menu>
				</template>
			</v-data-table>
		</div>
	</div>
</template>

<script>
	import ProposalService from '@/modules/proposals/ProposalService';
	import ProposalBuilder from '@/modules/proposals/ProposalBuilder';
	import NewProposal from '@/modules/proposals/NewProposal';
	import DateTime from '@/modules/utils/HDateTime';
	import FilterHelpers from '@/utils/FilterHelpers';
	import EmptyFilteredResults from '@/components/EmptyFilteredResults';
	import EmptyView from '@/components/EmptyView';
	import ClientAvatar from '@/components/ClientAvatar';
	import AgreementBuilder from '@/modules/agreements/AgreementBuilder';
	import AgreementService from '@/modules/agreements/AgreementService';
	import ConfirmModal from '@/components/ConfirmModal';
	import TemplateService from '@/modules/templates/TemplateService';

	export default {
		name: 'ProposalList',
		props: ['client', 'isActive', 'filter', 'proposalId'],
		components: { EmptyFilteredResults, EmptyView, ClientAvatar },

		data() {
			return {
				proposalService: new ProposalService(),
				agreementService: new AgreementService(),
				templateService: new TemplateService(),
				proposalList: [],
				agreementList: [],
				initialized: false,
				DateTime: DateTime,
				refreshKey: 0,
			};
		},

		mounted() {
			if (this.proposalId && this.client) {
				this.openProposalById(this.proposalId, this.client.id);
			}
		},

		methods: {
			openAgreement: function(agreement) {
				if (agreement.version === 2) {
					this.openV2Agreement(agreement.id);
				} else {
					this.openProposal(agreement);
				}
			},

			duplicateAgreement: function(agreement) {
				this.$store.commit('startLoading');
				this.agreementService
					.cloneAgreement(agreement.id, this.client.id)
					.then((res) => {
						this.openV2Agreement(res.data.id);
					})
					.catch((err) => this.$store.commit('error', err.response.data.message))
					.finally(() => this.$store.commit('stopLoading'));
			},

			saveAsTemplate: function(agreement) {
				this.$store.commit('startLoading');
				if (agreement.version === 2) {
					this.templateService
						.agreementTemplateFromAgreement(agreement.id, 'Template from: ' + agreement.name)
						.then((res) => {
							console.log(res);
							this.$router.push(`/templates/agreements?v=2&id=${res.data.id}`);
						})
						.finally(() => this.$store.commit('stopLoading'));
				} else {
					this.templateService
						.createTemplateFromProposal(agreement.id, 'Template from: ' + agreement.name)
						.then((res) => {
							console.log(res);
							this.$router.push(`/templates/agreements?v=1&id=${res.data.id}`);
						})
						.finally(() => this.$store.commit('stopLoading'));
				}
			},

			confirmDelete: function(agreement) {
				let binding = {
					headingText: 'Confirm',
					bodyText: `Are you sure you want to delete the "${agreement.name}" agreement?`,
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						if (agreement.version === 1) {
							this.deleteProposal(agreement);
						} else {
							this.deleteAgreement(agreement);
						}
					}
				});
			},

			deleteAgreement(agreement) {
				this.$store.commit('startLoading');
				this.agreementService
					.deleteAgreement(agreement.id)
					.then(() => {
						let ix = this.agreementList.findIndex((a) => a.id === agreement.id);
						if (ix > -1) {
							this.agreementList.splice(ix, 1);
						}
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			deleteProposal(proposal) {
				this.$store.commit('startLoading');
				this.proposalService
					.deleteProposal(proposal.client.id, proposal.id)
					.then(() => {
						let ix = this.proposalList.findIndex((p) => p.id === proposal.id);
						if (ix > -1) {
							this.proposalList.splice(ix, 1);
						}
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			openV2Agreement: function(id) {
				this.$store.state.globalModalController.openModal(AgreementBuilder, { id: id }, true, true).then((res) => {
					if (res) {
						let ix = this.agreementList.findIndex((a) => a.id === res.id);
						if (res.deleted) {
							if (ix > -1) {
								this.agreementList.splice(ix, 1);
							}
						} else {
							res.lastEvent = res.events[res.events.length - 1];
							if (ix > -1) {
								this.agreementList.splice(ix, 1, res);
							} else {
								this.agreementList.push(res);
							}
						}
					}
				});
			},

			addNew: function() {
				this.$store.state.globalModalController
					.openModal(NewProposal, { clientId: this.client ? this.client.id : null }, true, false)
					.then((res) => {
						if (!res) {
							//no-op
						}
						if (res.action === 'UPGRADE') {
							setTimeout(() => {
								this.$router.push('/subscription');
							}, 300);
						} else if (res.action === 'NEW_AGREEMENT') {
							this.openV2Agreement(res.id);
						} else {
							this.openProposalById(res.id, res.clientId);
						}
					});
			},

			openProposalById: function(proposalId, clientId) {
				this.openProposal({ id: proposalId, clientMini: { id: clientId } });
			},

			openProposal: function(proposal) {
				let clientId = proposal.clientMini ? proposal.clientMini.id : proposal.client.id;
				let binding = {
					proposalId: proposal.id,
					clientId: clientId,
				};
				this.$store.state.globalModalController.openModal(ProposalBuilder, binding, true, true).then((res) => {
					if (!res) {
						return;
					}
					if (res === 'newProjectCreated') {
						this.$emit('newProjectCreated');
					}

					this.getProposalList();
				});
			},

			getAgreementList: function() {
				this.agreementService
					.getAgreements(this.client ? this.client.id : null)
					.then((res) => {
						this.agreementList.splice(0);
						this.agreementList.push(...res.data);
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			},

			getProposalList: function() {
				let result;

				if (this.client) {
					result = this.proposalService.getProposalList(this.client.id);
				} else {
					result = this.proposalService.getProposalListByAccount();
				}

				result
					.then((res) => {
						this.proposalList.splice(0, this.proposalList.length);
						this.proposalList.push(...res.data);
						this.initialized = true;
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			},

			sortByDate: function(a, b) {
				return b.date.localeCompare(a.date);
			},

			formatStatus(status) {
				let background, color;

				if (status === 'Signed' || status === 'Fully Executed') {
					background = '--v-success_10-base';
					color = '--v-success-base';
				} else if (status === 'Declined') {
					background = '--v-alert_10-base';
					color = '--v-alert-base';
				} else if (status === 'Sent' || status === 'Finalized') {
					background = '--v-warning_10-base';
					color = '--v-black-base';
				} else {
					background = '--v-gray_20-base';
					color = '--v-gray_80-base';
				}

				color = '--v-gray_80-base';

				return `<div class="proposal-status" style="--color:var(${color});--background:var(${background})">${status}</div>`;
			},
		},

		computed: {
			headers: function() {
				let result = [
					{ text: 'Name', value: 'name' },
					{ text: 'Client', value: 'client', sortable: true, sort: (a, b) => a.name.localeCompare(b.name) },
					{ text: 'Date created', value: 'date' },
					{
						text: 'Last activity',
						value: 'lastEvent',
						sortable: true,
						sort: (a, b) => a.timestamp.localeCompare(b.timestamp),
					},
					{ text: 'Status', value: 'status' },
					{ text: 'Version', value: 'version' },
					{ text: null, value: 'action', align: 'right', sortable: false },
				];

				if (this.client) {
					result.splice(1, 1);
				}

				return result;
			},

			normalizedProposals: function() {
				let result = [];

				this.proposalList.forEach((p) => {
					result.push({
						id: p.id,
						name: p.coverPage?.heading,
						client: p.client,
						date: p.dateCreated,
						dateCompleted: p.dateSigned,
						status: p.proposalStatus,
						type: p.proposalType,
						lastEvent: p.lastEvent,
						version: 1,
					});
				});

				return result;
			},

			normalizedAgreements: function() {
				let result = [];

				this.agreementList.forEach((a) => {
					result.push({
						id: a.id,
						name: a.name,
						client: a.client,
						date: a.dateCreated,
						dateCompleted: a.dateCompleted,
						status: a.fullyExecuted ? 'Fully Executed' : a.status,
						type: 'Agreement',
						lastEvent: a.lastEvent,
						version: 2,
					});
				});

				return result;
			},

			filteredProposalList: function() {
				let result = [];
				result.push(...this.normalizedProposals);
				result.push(...this.normalizedAgreements);

				let search = this.filter.search ? this.filter.search.toLowerCase() : null;
				let earliest, latest;

				if (this.filter.dateSpecifier) {
					if (this.filter.dateSpecifier === 'between' && this.filter.earliest && this.filter.latest) {
						earliest = DateTime.fromISO(this.filter.earliest);
						latest = DateTime.fromISO(this.filter.latest);
					} else if (this.filter.dateSpecifier !== 'between') {
						let helper = FilterHelpers.getEarliestAndLatest(this.filter.dateSpecifier, false);
						earliest = helper.earliest;
						latest = helper.latest;
					}
				}

				result = result
					.filter((p) => {
						if (search) {
							return !!p.name?.toLowerCase().includes(search.toLowerCase());
						} else {
							return true;
						}
					})
					.filter((p) => {
						if (this.filter.clients && this.filter.clients.length && p.client) {
							return this.filter.clients.includes(p.client.id);
						} else {
							return true;
						}
					})
					.filter((p) => {
						if (earliest && latest) {
							let dateCreated = DateTime.fromISO(p.date);
							return dateCreated >= earliest && dateCreated < latest;
						} else {
							return true;
						}
					})
					.filter((p) => {
						if (this.filter.proposalType && this.filter.proposalType.length) {
							return this.filter.proposalType.includes(p.type);
						} else {
							return true;
						}
					})
					.filter((p) => {
						if (this.filter.proposalStatus && this.filter.proposalStatus.length) {
							return this.filter.proposalStatus.includes(p.status);
						} else {
							return true;
						}
					});

				result.sort(this.sortByDate);
				return result;
			},

			empty: function() {
				return this.proposalList.length === 0 && this.agreementList.length === 0;
			},
		},

		watch: {
			isActive: function(newVal) {
				if (newVal && !this.initialized) {
					this.getProposalList();
					this.getAgreementList();
				}
			},

			proposalDetailModal: function(newVal) {
				if (!newVal) {
					this.getProposalList();
					this.getAgreementList();
				}
			},

			'client.id': {
				deep: true,
				immediate: true,
				handler() {
					if (this.isActive) {
						this.getProposalList();
						this.getAgreementList();
					}
				},
			},
		},
	};
</script>

<style lang="scss">
	.proposal-status {
		width: fit-content;
		padding: 4px 8px;
		flex: none;
		order: 0;
		flex-grow: 0;
		background-color: var(--background);
		color: var(--color);
		border-radius: 4px;
		font-size: 12px;
	}
</style>

<style scoped lang="scss">
	.proposal-wrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		overflow-y: auto;
	}

	.proposal-card {
		width: 260px;
		height: 310px;
		border: 1px solid var(--v-gray_50-base);
		border-radius: 4px;
		box-shadow: 0px 1px 0px var(--v-gray_50-base);
		cursor: pointer;

		&:hover {
			background-color: var(--v-gray_10-base);
		}
	}

	.image-preview {
		width: 259px;
		height: 140px;
		background-size: cover;
		border-radius: 4px 4px 0px 0px;
	}
</style>
