<template>
	<div>
		<v-container class="pa-0 ma-0" style="max-width: 500px">
			<v-row dense>
				<v-col cols="12">
					<workspace-selector></workspace-selector>
				</v-col>
				<v-col cols="12" class="pb-4">
					<v-container>
						<v-row>
							<v-col cols="6" class="text-left">
								<div class="font-gray_70 font-12 mb-1">Account</div>
								<settings-link v-if="isFullUser" label="My business" route="/account" icon="storefront"></settings-link>
								<settings-link v-if="isFullUser" label="Subscription" route="/subscription/workspace" icon="credit_card"></settings-link>
								<settings-link v-if="isFullUser" label="Subscription add-ons" route="/subscription/add-ons" icon="add_shopping_cart"></settings-link>
								<settings-link v-if="$store.getters.hasFeatureAccess('settings')" label="Data import/export" route="/data/import" icon="upload"></settings-link>
								<settings-link v-if="isFullUser" label="Team members" route="/team" icon="group"></settings-link>
								<settings-link label="Refer a friend, earn $50" route="/referrals" icon="sentiment_satisfied"></settings-link>
							</v-col>
							<v-col cols="6" class="text-left">
								<div class="font-gray_70 font-12 mb-1">Settings</div>
								<settings-link label="Integrations" route="/apps" icon="extension"></settings-link>
								<settings-link v-if="isFullUser" label="Stripe payments" route="/stripe" icon="$stripe"></settings-link>
								<settings-link v-if="$store.getters.hasFeatureAccess('settings')" label="Custom fields" route="/custom-fields/clients" icon="dataset"></settings-link>
								<hr class="my-1" style="border-top: 1px solid var(--v-gray_50-base)">
								<settings-link label="Preferences" route="/preferences" icon="settings"></settings-link>
								<settings-link label="Theme" :children="theme" :icon="themeIcon" @selected="handleThemeSelection($event)" :close-on-content-click="false"></settings-link>
							</v-col>
						</v-row>
					</v-container>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import AuthService from "@/modules/auth/AuthService";
import PodService from "@/services/PodService";
import WorkspaceSelector from "@/views/nav/settings/WorkspaceSelector";
import SettingsLink from "@/views/nav/settings/SettingsLink";

export default {
	name: "SettingsNav",

	props: [],

	components: {SettingsLink, WorkspaceSelector},

	data: function () {
		return {
			theme: [
				{ label: 'Moxie', selectValue: 'moxie', icon: '$lightMode'},
				{ label: 'Professional', selectValue: 'professional', icon: '$lightMode'},
				{ label: 'Warm', selectValue: 'warm', icon: '$lightMode'},
				{ label: 'Eco friendly', selectValue: 'eco', icon: '$lightMode'},
				{ label: 'Bold', selectValue: 'bold', icon: '$lightMode'},
				{ label: 'Minimalist', selectValue: 'minimalist', icon: '$lightMode'},
				{ label: '80\'s Party', selectValue: 'party', icon: '$lightMode'},
				{ label: 'Dark mode', selectValue: 'always', icon: '$darkMode'},
				{ label: 'System default', selectValue: 'automatic', icon: '$automaticMode' }
			],
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		handleThemeSelection: function(theme){
			this.$store.state.eventBus.$emit('handle-theme-selection',theme);
		},

		doLogout() {
			this.dialog = false;
			try {
				window.google.accounts.id.disableAutoSelect();
			}catch(err){
				console.log(err);
			}

			AuthService.logout()
					.then(() => {
						console.log('Logout Success');
						this.podLogout();
					})
					.catch((err) => {
						console.log(err);
						this.podLogout();
					});
		},

		podLogout() {
			let podService = new PodService();
			podService
					.clearCookies()
					.then(() => {
						this.finalizeLogout();
					})
					.catch(() => {
						this.finalizeLogout();
					});
		},


		finalizeLogout() {
			this.$store.commit('logOut');
			location.reload();
		},
	},

	computed: {
		isFullUser(){
			return this.$store.getters.isAccountFullUser;
		},

		themeIcon: function(){
			if(this.$store.state.darkMode === 'never'){
				return 'light_mode';
			}else if(this.$store.state.darkMode === 'always'){
				return 'dark_mode';
			}else{
				return 'settings_brightness'
			}
		}
	},

}
</script>

<style scoped lang="scss">

</style>