<template>
	<div id="new-ticket">
		<v-btn icon class="close-dialog" @click="handleClose()">
			<v-icon>$close</v-icon>
		</v-btn>
		<v-form ref="form" v-model="isValid" @submit.prevent>
			<div class="modal-padding column-format gap-2">
				<div class="modal-title">
					<span>Create new ticket</span>
				</div>
				<select-client v-model="selectedClientId" :dense="true" :disabled="clientLocked" :show-avatar="true" :hide-archive="true"></select-client>
				<v-autocomplete
					v-model="contactId"
					:items="contacts"
					outlined
					dense
					hide-details
					persistent-placeholder
					label="Contact"
					item-value="clientPortalUserId"
					:search-input.sync="searchQuery"
					:filter="customFilter"
					:disabled="contacts.length === 0"
				>
					<template v-slot:item="{ item }">
						<div>{{ item.firstName }} {{ item.lastName }} ({{ item.email }})</div>
					</template>
					<template v-slot:selection="{ item }">
						<div>{{ item.firstName }} {{ item.lastName }} ({{ item.email }})</div>
					</template>
				</v-autocomplete>
				<v-text-field
						outlined
						dense
						hide-details
						persistent-placeholder
						label="CC"
						placeholder="Comma separated list of emails"
						v-model="cc"
				></v-text-field>
				<v-autocomplete
					v-model="ticketType"
					:items="ticketTypes"
					outlined
					dense
					hide-details
					persistent-placeholder
					label="Ticket type"
					item-value="id"
					item-text="type"
				>
				</v-autocomplete>
				<v-text-field
						outlined
						dense
					hide-details
					persistent-placeholder
					:rules="subjectRules"
					label="Subject"
					v-model="subject"
					autofocus
				></v-text-field>
				<editor
						v-if="showTinyMCE"
						id="tinymce-editor"
						ref="commentEditor"
						:init="mceConfig"
						rows="3"
						auto-grow
						v-model="comment"
				>
				</editor>
				<v-file-input
					v-model="attachments"
					persistent-placeholder
					hide-details
					dense
					outlined
					label="Attachments"
					prepend-icon="attach_file"

					multiple
				></v-file-input>
			</div>
			<div class="modal-footer">
				<v-btn class="secondary-action mr-1" width="130" @click="handleClose()">
					{{ $t('global.cancel') }}
				</v-btn>
				<v-btn class="super-action ml-1" width="130" @click="createTicket()" :disabled="!isValid">
					{{ $t('global.create') }}
				</v-btn>
			</div>
		</v-form>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import SelectClient from '@/modules/clients/SelectClient';
	import CommunicatorTicketService from '@/modules/communicator/CommunicatorTicketService';
	import editor from '@tinymce/tinymce-vue';

	export default defineComponent({
		name: 'CreateTicket',

		props: ['clientId'],

		components: { SelectClient, editor },

		data: function() {
			return {
				ticketService: new CommunicatorTicketService(),
				subjectRules: [(v) => (v && v.trim().length >= 1) || 'Subject is required'],
				isValid: false,
				subject: null,
				cc: null,
				comment: null,
				contactId: null,
				selectedClientId: null,
				attachments: [],
				ticketType: null,
				ticketSettings: null,
				clientLocked: false,
				showTinyMCE: false,
				searchQuery: '',

				mceConfig: {
					menubar: false,
					inline: false,
					paste_as_text: false,
					browser_spellcheck: true,
					paste_data_images: true,
					table_style_by_css: true,
					height: 500,
					statusbar: false,
					placeholder: 'Ticket details...',
					extended_valid_elements: 'iframe[src,id,style], style[media|type], link[rel,type,href]',
					forced_root_block: 'div',
					plugins: ['paste', 'lists', 'link', 'table', 'autoresize', 'media', 'code'],
					contextmenu: false,
					indentation: '12pt',
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					toolbar: [
						'bold italic underline forecolor | alignleft aligncenter ' +
						'alignright alignjustify | bullist numlist formatting_group | link unlink',
					],
					content_style:
							"@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap'); body { font-family: 'Inter', sans-serif; font-size:14px }",

					toolbar_groups: {
						formatting_group: {
							icon: 'format',
							tooltip: 'Formatting',
							items: 'fontsizeselect fontselect | indent outdent table | removeformat',
						},
					},
					style_formats: [
						{ title: 'Paragraph', format: 'p' },
						{ title: 'Title', format: 'h1' },
						{ title: 'Heading', format: 'h2' },
						{ title: 'Subheading', format: 'h3' },
						{ title: 'Code', format: 'code' },
					],
					table_toolbar:
							'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
				},
			};
		},

		mounted() {
			this.getTicketSettings();
			if(this.clientId){
				this.selectedClientId = this.clientId;
				this.clientLocked = true;
			}

			this.$nextTick(() => {
				this.showTinyMCE = true;
			});
		},

		beforeUnmount() {
			this.showTinyMCE = false;
		},

		methods: {
			customFilter(item, query) {
				const searchTerm = query.toLowerCase();
				if(item.firstName && item.firstName.toLowerCase().startsWith(searchTerm)){
					return true;
				}else if(item.lastName && item.lastName.toLowerCase().startsWith(searchTerm)){
					return true;
				}else if(item.email && item.email.toLowerCase().startsWith(searchTerm)){
					return true;
				}else{
					return false;
				}
			},

			handleClose: function() {
				this.$emit('result');
			},

			createTicket: function() {
				if (!this.subject) {
					this.$store.commit('error', 'Subject is required');
					return;
				} else if (!this.selectedClientId) {
					this.$store.commit('error', 'Client is required');
					return;
				} else if (!this.ticketType) {
					this.$store.commit('error', 'Ticket type is required');
					return;
				}

				let cc = this.cc ? this.cc.split(",").map(item => item.trim()) : [];

				let newTicket = {
					ticketType: this.ticketType,
					clientId: this.selectedClientId,
					clientContactId: this.contactId,
					subject: this.subject,
					comment: this.comment,
					commentFormat: 'HTML',
					ccList: cc,
				};

				this.$store.commit('startLoading');

				this.ticketService
					.createTicket(newTicket, this.attachments)
					.then((res) => {
						this.$emit('result', res.data);
					})
					.catch((err) => this.$store.commit('error', err.response.data.message))
						.finally(() => this.$store.commit('stopLoading'));
			},

			getTicketSettings: function() {
				this.ticketService.getTicketSettings().then((res) => {
					this.ticketSettings = res.data;
				});
			},
		},

		computed: {
			contacts: function() {
				if (this.selectedClientId) {
					return this.$store.getters.contacts.filter((c) => c.clientId === this.selectedClientId);
				} else {
					return [];
				}
			},

			ticketTypes: function() {
				if (this.ticketSettings) {
					return this.ticketSettings.ticketTypes;
				} else {
					return [];
				}
			},
		},
	});
</script>

<style scoped lang="scss">
	#new-ticket {
		width: 100%;
		background-color: var(--v-white-base);
	}
</style>
