import { render, staticRenderFns } from "./WorkBundle.vue?vue&type=template&id=8398ff12&scoped=true&"
import script from "./WorkBundle.vue?vue&type=script&lang=js&"
export * from "./WorkBundle.vue?vue&type=script&lang=js&"
import style0 from "./WorkBundle.vue?vue&type=style&index=0&id=8398ff12&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8398ff12",
  null
  
)

export default component.exports