<template>
	<div class="column-format" v-if="isReady">
		<slot name="filter"></slot>
		<div style="background-color: var(--v-white-base)" class="mt-4">
			<v-data-table
				:headers="headers"
				:items="filteredMeetingList"
				@click:row="openScheduledMeeting($event.id)"
				style="cursor: pointer"
				sort-by="confirmedTime.start"
				sort-asc
				:disable-pagination="true"
				:hide-default-footer="true"
			>
				<template v-slot:item.confirmedTime.start="{ item }">
					<span :class="`${item.meetingStatus === 'Cancelled' ? 'cancelled' : ''}`">{{
						DateTime.fromISO(item.confirmedTime.start).toFormat('ccc') +
							' ' +
							DateTime.fromISO(item.confirmedTime.start).toLocaleString(DateTime.DATETIME_MED)
					}}</span>
				</template>

				<template v-slot:item.meetingName="{ item }">
					<span :class="`${item.meetingStatus === 'Cancelled' ? 'cancelled' : ''}`">{{ item.meetingName }}</span>
				</template>

				<template v-slot:item.contact="{ item }">
					<span :class="`${item.meetingStatus === 'Cancelled' ? 'cancelled' : ''}`"
						>{{ item.formData.firstName }} {{ item.formData.lastName }}</span
					>
				</template>

				<template v-slot:item.action="{ item }">
					<meeting-action-menu
						:hide-duplicate="true"
						@view="openScheduledMeeting(item.id)"
						@delete="confirmDelete(item)"
					></meeting-action-menu>
				</template>

				<template v-slot:no-data>
					<div v-if="!scheduledMeetings.length">
						There aren't any meetings scheduled yet.
					</div>
					<div v-else>
						There are no meetings that match the filter criteria.
					</div>
				</template>
			</v-data-table>
		</div>
	</div>
</template>

<script>
	import ScheduledMeetingService from '@/modules/meetings/ScheduledMeetingService';
	import DateTime from '@/modules/utils/HDateTime';
	import MeetingDetail from '@/modules/meetings/MeetingDetail';
	import FilterHelpers from '@/utils/FilterHelpers';
	import ConfirmModal from '@/components/ConfirmModal';
	import MeetingActionMenu from '@/modules/meetings/MeetingActionMenu';

	export default {
		name: 'MeetingList',

		props: ['client', 'filter'],

		components: { MeetingActionMenu },

		data: function() {
			return {
				scheduledService: new ScheduledMeetingService(),
				tableKey: 0,
				scheduledMeetings: [],
				isReady: false,
				DateTime: DateTime,
			};
		},

		mounted() {
			this.$track.record('page-view', { module: 'meeting-list' });
			this.getScheduledMeetings();
			this.$store.state.eventBus.$on('account-changed', this.getScheduledMeetings);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.getScheduledMeetings);
		},

		methods: {
			getScheduledMeetings: function() {
				this.scheduledService.getScheduledMeetingList(this.client.id).then((res) => {
					this.scheduledMeetings.splice(0, this.scheduledMeetings.length);
					this.scheduledMeetings.push(...res.data);
					this.isReady = true;
				});
			},

			openScheduledMeeting: function(id) {
				this.$store.state.globalModalController.openModal(MeetingDetail, { id: id }).then((res) => {
					let ix = this.scheduledMeetings.findIndex((m) => m.id === id);
					if (res && res.action !== 'DELETED') {
						if (ix > -1) this.scheduledMeetings.splice(ix, 1, res);
					} else if (res && res.action === 'DELETED') {
						let ix = this.scheduledMeetings.findIndex((m) => m.id === id);
						if (ix > -1) this.scheduledMeetings.splice(ix, 1);
					}
				});
			},

			confirmDelete: function(meeting) {
				let binding = {
					headingText: 'Confirm meeting delete!',
					bodyText: 'Are you sure you want to delete this scheduled meeting?  This cannot be undone.',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.$store.commit('startLoading');
						this.scheduledService
							.deleteScheduledMeeting(meeting.id)
							.then(() => {
								this.getScheduledMeetings();
							})
							.finally(() => this.$store.commit('stopLoading'));
					}
				});
			},
		},

		watch: {},

		computed: {
			paidAccount() {
				return this.$store.getters.isPaidAccount;
			},

			sampleMode() {
				return this.$store.getters.isSampleMode;
			},

			filteredMeetingList: function() {
				let result = [...this.scheduledMeetings];
				let search = this.filter.search ? this.filter.search.toLowerCase() : null;
				return result
					.filter((m) => {
						if (
							!search ||
							m.formData.email.toLowerCase().startsWith(search) ||
							m.formData.firstName.toLowerCase().startsWith(search) ||
							m.formData.lastName.toLowerCase().startsWith(search) ||
							m.meetingName.toLowerCase().includes(search)
						) {
							return true;
						} else if (search) {
							return false;
						}
					})
					.filter((m) => {
						if (this.earliest && this.latest) {
							let compareDate = DateTime.fromISO(m.confirmedTime.start);
							if (compareDate >= this.earliest && compareDate <= this.latest) {
								return true;
							} else {
								return false;
							}
						} else {
							return true;
						}
					});
			},

			earliest: function() {
				if (this.filter.dateSpecifier) {
					if (this.filter.dateSpecifier === 'between') {
						return DateTime.fromISO(this.filter.earliest);
					} else {
						return FilterHelpers.getEarliestAndLatest(this.filter.dateSpecifier, false).earliest;
					}
				} else {
					return DateTime.now().startOf('day');
				}
			},

			latest: function() {
				if (this.filter.dateSpecifier) {
					if (this.filter.dateSpecifier === 'between') {
						return DateTime.fromISO(this.filter.latest);
					} else {
						return FilterHelpers.getEarliestAndLatest(this.filter.dateSpecifier, false).latest;
					}
				} else {
					return DateTime.now()
						.plus({ months: 12 })
						.endOf('month');
				}
			},

			filterStateKey: function() {
				return 'MEETING_LIST_FILTERS_' + this.$store.getters.getAccountId + '_' + this.$store.getters.getLoggedInUserId;
			},

			headers: function() {
				return [
					{ text: this.$t('meeting.scheduled.name'), value: 'meetingName' },
					{ text: this.$t('meeting.scheduled.start'), value: 'confirmedTime.start' },
					{ text: this.$t('contact.name'), value: 'contact' },
					{ text: '', value: 'action', sortable: false, align: 'right' },
				];
			},
		},
	};
</script>

<style scoped lang="scss">
	.cancelled {
		text-decoration: line-through;
	}
</style>
