<template>
	<div class="column-format fill-height" v-if="isReady">
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' }, { label: 'Sales pipeline' }]">
			{{ view === 'opportunities' ? 'Opportunities' : 'Forecast' }}
		</page-header>

		<div v-if="paidAccount || sampleMode" class="column-format gap-3">
			<div class="row-format align-center gap-3 flex-wrap">
				<v-text-field
					outlined
					hide-details
					dense
					v-model="filter.name"
					placeholder="Search..."
					style="max-width: 200px; background-color: var(--v-white-base)"
					color="gray_30"
				>
					<template v-slot:prepend-inner><span class="material-symbols-rounded font-gray_50">search</span></template>
				</v-text-field>
				<pipeline-filter
					:filter="filter"
					:clients="uniqueClients"
					:stages="pipelineStages.stages"
					@reset-filter="resetFilter"
				></pipeline-filter>

				<pipeline-sort :filter="filter" :view="opportunityView"></pipeline-sort>

				<div class="ml-auto row-format align-center">
					<pagination
						v-model="currentPage"
						:total-pages="totalPages"
						v-if="totalPages > 0 && view === 'opportunities' && opportunityView === 'TABLE'"
					></pagination>
					<v-btn class="super-action" @click="createOpportunity()"
						><v-icon size="20">add</v-icon> Add opportunity</v-btn
					>
				</div>
			</div>
			<div :class="$store.getters.scroll">
				<div v-if="opportunities.length">
					<div
						class="row-format gap-4 pb-3 flex-wrap"
						v-if="view === 'opportunities' && opportunityView === 'TABLE'"
					>
						<div
							class="stage-overview row-format centered pointer"
							:style="`--stage-color:${stage.rgb.join(',')}`"
							v-for="stage in stageSummaries"
							:key="stage.id"
							@click="focusStage(stage)"
						>
							<div>
								{{ stage.label }} ({{ stage.opportunityCount }}) - {{ $formatters.dollars(stage.totalValue) }}
							</div>
						</div>
					</div>
					<div
							class="row-format gap-4 pb-3"
					>
						<div class="ml-auto row-format gap-3" style="align-items: end">
							<div
									class="pointer row-format"
									v-tippy="{ content: 'Table' }"
									@click="setOpportunityView('TABLE')"
							>
								<h-icon
										name="project-table"
										size="20"
										:color="`var(--v-${opportunityView === 'TABLE' ? 'primary' : 'gray_70'}-base)`"
								></h-icon>
							</div>
							<div
									class="pointer row-format"
									v-tippy="{ content: 'Kanban' }"
									@click="setOpportunityView('CARD')"
							>
								<h-icon
										name="project-kanban"
										size="20"
										:color="`var(--v-${opportunityView === 'CARD' ? 'primary' : 'gray_70'}-base)`"
								></h-icon>
							</div>
						</div>
					</div>

					<div class="show-scrollbar">
						<opportunity-list
							v-if="view === 'opportunities' && opportunityView === 'TABLE'"
							:pipeline-stages="pipelineStages"
							:pipeline-fields="pipelineFields"
							:opportunities="paginatedItems"
							:confidence-list="confidenceList"
							@edit="editOpportunity($event)"
							@archive="archiveOpportunity($event)"
							@delete="confirmDelete($event)"
						>
						</opportunity-list>
					</div>

					<opportunity-kanban
						v-if="view === 'opportunities' && opportunityView === 'CARD'"
						:pipeline-stages="pipelineStages"
						:pipeline-fields="pipelineFields"
						:stage-summaries="stageSummaries"
						:opportunities="filteredOpportunities"
						:confidence-list="confidenceList"
						:filter="filter"
						@edit="editOpportunity($event)"
						@kanban-updates="updateKanban($event)"
						@add-opportunity="createOpportunity($event)"
					>
					</opportunity-kanban>

					<forecast
						v-if="view === 'forecast'"
						:opportunities="filteredOpportunities"
						:pipeline-stages="pipelineStages"
					></forecast>
				</div>
				<div v-else class="row-format centered fill-height" style="min-height: calc(100vh - 300px)">
					<empty-view
						header="Never have another client dry spell"
						body="Visualize the prospects you’re talking to, track notes on what they need, pitch them on proposals, work your way to a signed contract and getting paid."
						cta="Add an opportunity"
						video-header="See how it works"
						video-body="Learn how the pipeline visualizes your sales process, predicts your income, and helps you keep all of your opportunities on track to close."
						video-cta="Watch the tutorial"
						video-id="f72IvZ0Ifm8"
						@cta-clicked="createOpportunity()"
					></empty-view>
				</div>
			</div>
		</div>
		<div v-else class="row-format centered mx-8 mt-4">
			<free-tier-card
				style="width:100%; height: 150px"
				message="Sales pipeline is only available on the Pro or Teams plan.  Upgrade now to get control over your business and visualize the flow of opportunities through the pipeline."
				@upgrade-clicked="$router.push('/subscription')"
			></free-tier-card>
		</div>
	</div>
</template>

<script>
	import OpportunityList from '@/modules/pipeline/list/OpportunityList';
	import Forecast from '@/modules/pipeline/Forecast';
	import PipelineFilter from '@/modules/pipeline/PipelineFilter';
	import PipelineSort from '@/modules/pipeline/PipelineSort';
	import chroma from 'chroma-js';
	import EmptyView from '@/components/EmptyView';
	import FreeTierCard from '@/components/FreeTierCard';
	import OpportunityKanban from '@/modules/pipeline/kanban/OpportunityKanban';
	import OpportunityMixin from '@/modules/pipeline/OpportunityMixin';
	import Pagination from '@/components/Pagination';
	import PageHeader from "@/components/PageHeader";

	export default {
		name: 'PipeLineV2',

		props: [],

		mixins: [OpportunityMixin],

		components: {
			OpportunityKanban,
			PipelineSort,
			PipelineFilter,
			Forecast,
			OpportunityList,
			EmptyView,
			FreeTierCard,
			Pagination,
			PageHeader
		},

		data: function() {
			return {
				channelName: null,
				opportunityView: 'TABLE',
				showMenu: false,
				filter: this.getFilter(),
				currentPage: 1,
				pageSize: 20,
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
			this.$store.state.eventBus.$on('pipeline_onboarding_tasks', this.handleOnboardingTask);
			this.initialize();
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
			this.$store.state.eventBus.$off('pipeline_onboarding_tasks', this.handleOnboardingTask);
			this.shutDown();
		},

		methods: {
			handleOnboardingTask: function(type) {
				if (type === 'create-opportunity') {
					this.createOpportunity();
				}
			},

			initialize: function() {
				this.getOpportunityView();
				let p1 = this.getPipelineStages();
				let p2 = this.getOpportunities();
				Promise.all([p1, p2]).then(() => {
					this.checkDefaultOpen();
					this.isReady = true;
				});

				this.channelName = this.getChannelName();
				this.$store.state.eventBus.$on(this.channelName, this.eventHandler);
			},

			shutDown: function() {
				this.$store.state.eventBus.$off(this.channelName, this.eventHandler);
			},

			handleAccountChange: function() {
				this.shutDown();
				this.initialize();
			},

			eventHandler: function(event) {
				if (event.userMetadata === 'Opportunity') {
					this.processOpportunityEvent(event.message);
				} else if (event.userMetadata === 'KanbanUpdate') {
					this.processKanbanUpdate(event.message);
				}
			},

			processKanbanUpdate: function(updates) {
				for (let i = 0; i < updates.length; i++) {
					let id = updates[i].id;
					let kanbanSort = updates[i].kanbanSort;
					let statusId = updates[i].statusId;

					let ix = this.opportunities.findIndex((o) => o.id === id);
					if (ix > -1) {
						let opp = this.opportunities[ix];
						opp.kanbanSort = kanbanSort;
						opp.statusId = statusId;
						this.opportunities.splice(ix, 1, opp);
					}
				}
			},

			processOpportunityEvent: function(opportunity) {
				let ix = this.opportunities.findIndex((o) => o.id === opportunity.id);
				if (opportunity.statusId === '__deleted__') {
					if (ix > -1) {
						this.opportunities.splice(ix, 1);
					}
				} else {
					if (ix > -1) {
						this.opportunities.splice(ix, 1, opportunity);
					} else {
						this.opportunities.push(opportunity);
					}
				}
			},

			setOpportunityView: function(view) {
				this.opportunityView = view;
				localStorage.setItem(this.currentViewKey, view);
			},

			getOpportunityView: function() {
				let currentView = localStorage.getItem(this.currentViewKey);
				if (currentView) {
					this.opportunityView = currentView;
				}
			},

			getChannelName() {
				return this.$store.getters.getMessageChannelPrefix + '.opp';
			},

			updated: function() {
				this.$emit('updated', this.filter);
			},

			checkDefaultOpen: function() {
				if (this.$route.query && this.$route.query.opportunityId) {
					window.history.pushState({}, document.title, this.$route.path);
					this.editOpportunity(this.$route.query.opportunityId);
				}
			},

			getFilter: function() {
				let savedFilter = localStorage.getItem('PIPELINE_FILTER');

				if (savedFilter) {
					return JSON.parse(savedFilter);
				} else {
					return this.getDefaultFilter();
				}
			},

			getDefaultFilter: function() {
				return {
					sort: 'Status',
					stages: [],
					clients: [],
					sentimentType: 'Any',
					sentimentValue: 2,
					valueType: 'Any',
					value: 0,
					archiveStatus: 'active',
				};
			},

			resetFilter: function() {
				this.filter = this.getDefaultFilter();
			},

			focusStage: function(stage) {
				this.filter.stages.splice(0, this.filter.stages.length);
				if (stage.id != null) {
					this.filter.stages.push(stage.id);
				}
			},

			updateKanban: function(updates) {
				this.opportunityService.updateKanbanSettings(updates);
			},

			updateOpportunity: function(opportunity) {
				this.opportunityService.updateOpportunity(opportunity.id, opportunity).then((res) => {
					let ix = this.opportunities.findIndex((o) => o.id === opportunity.id);
					this.opportunities.splice(ix, 1, res.data);
				});
			},

			sortByStatus: function(a, b) {
				let ixA = this.pipelineStages.stages.findIndex((s) => s.id === a.statusId);
				let ixB = this.pipelineStages.stages.findIndex((s) => s.id === b.statusId);
				let result = ixA - ixB;

				if (result === 0) {
					result = this.sortByCloseDate(a, b);
					if (result === 0) {
						result = this.sortByName(a, b);
					}
				}

				return result;
			},

			sortByName: function(a, b) {
				let aName = a.name ? a.name : '';
				let bName = b.name ? b.name : '';
				return aName.localeCompare(bName);
			},

			sortByValue: function(a, b) {
				let aValue = a.totalValue ? a.totalValue : 0;
				let bValue = b.totalValue ? b.totalValue : 0;
				let result = bValue - aValue;
				if (result === 0) {
					return this.sortByName(a, b);
				} else {
					return result;
				}
			},

			sortByCloseDate: function(a, b) {
				let aDate = a.closeDate ? a.closeDate : '9999-99-99';
				let bDate = b.closeDate ? b.closeDate : '9999-99-99';
				let result = bDate.localeCompare(aDate);
				if (result === 0) {
					return this.sortByName(a, b);
				} else {
					return result;
				}
			},

			sortByNextDue: function(a, b) {
				let aDate = a.nextDue ? a.nextDue.dueDate : '9999-99-99';
				let bDate = b.nextDue ? b.nextDue.dueDate : '9999-99-99';
				let result = aDate.localeCompare(bDate);
				if (result === 0) {
					return this.sortByName(a, b);
				} else {
					return result;
				}
			},

			sortByLastActivity: function(a, b) {
				let aDate = a.lastActivity ? a.lastActivity.timestamp : '0000-00-00T00:00:00.000';
				let bDate = b.lastActivity ? b.lastActivity.timestamp : '0000-00-00T00:00:00.000';
				let result = bDate.localeCompare(aDate);
				if (result === 0) {
					return this.sortByName(a, b);
				} else {
					return result;
				}
			},

			sortByConfidence: function(a, b) {
				let result = b.sentiment - a.sentiment;
				if (result === 0) {
					return this.sortByName(a, b);
				} else {
					return result;
				}
			},

			sortByKanban: function(a, b) {
				let result = a.kanbanSort - b.kanbanSort;
				if (result === 0) {
					return this.sortByName(a, b);
				} else {
					return result;
				}
			},

			isInStageFilter: function(val) {
				if (this.filter.stages && this.filter.stages.length) {
					return this.filter.stages.includes(val.statusId);
				} else {
					return true;
				}
			},

			rangeFilter: function(symbol, comparison, value) {
				switch (symbol) {
					case '>=': {
						return value >= comparison;
					}
					case '<=': {
						return value <= comparison;
					}
					case '=': {
						return value == comparison;
					}
					default: {
						return true;
					}
				}
			},

			isInValueFilter: function(val) {
				if (this.filter.valueType && this.filter.valueType !== 'Any') {
					let comparison = parseFloat(this.filter.value);
					let value = val.totalValue ? val.totalValue : 0;
					return this.rangeFilter(this.filter.valueType, comparison, value);
				} else {
					return true;
				}
			},

			isInSentimentFilter: function(val) {
				if (this.filter.sentimentType && this.filter.sentimentType !== 'Any') {
					let comparison = this.filter.sentimentValue;
					let value = val.sentiment ? val.sentiment : 0;
					return this.rangeFilter(this.filter.sentimentType, comparison, value);
				} else {
					return true;
				}
			},

			isInClientFilter: function(val) {
				if (this.filter.clients && this.filter.clients.length) {
					return this.filter.clients.includes(val.clientId);
				} else {
					return true;
				}
			},

			isInArchiveFilter: function(val) {
				if (!this.filter.archiveStatus) {
					return true;
				} else if (this.filter.archiveStatus === 'active') {
					return !val.archive;
				} else if (this.filter.archiveStatus === 'archive') {
					return val.archive;
				} else {
					return true;
				}
			},

			isInTextFilter: function(val) {
				if (this.filter.name) {
					let search = this.filter.name.toLowerCase();
					if (val.name && val.name.toLowerCase().includes(search)) {
						return true;
					} else {
						for (const [key, value] of Object.entries(val.formData)) {
							if (
								key &&
								value &&
								value
									.toString()
									.toLowerCase()
									.includes(search)
							) {
								return true;
							}
						}

						let customValues = val.customValues;

						for (let j = 0; j < customValues.length; j++) {
							let cv = customValues[j];
							if (
								cv.value &&
								cv.value
									.toString()
									.toLowerCase()
									.includes(search.toLowerCase())
							) {
								return true;
							}
						}

						return false;
					}
				} else {
					return true;
				}
			},
		},

		watch: {
			filter: {
				deep: true,
				handler: function(val) {
					localStorage.setItem('PIPELINE_FILTER', JSON.stringify(val));
				},
			},
		},

		computed: {
			currentViewKey: function() {
				return 'PIPELINE_VIEW' + this.$store.getters.getAccountId + '_' + this.$store.getters.getLoggedInUserId;
			},

			getMessageChannelPrefix: (state, getters) => {
				return 'a-' + getters.getAccountId;
			},

			paidAccount() {
				return this.$store.getters.isPaidAccount;
			},

			sampleMode() {
				return this.$store.getters.isSampleMode;
			},

			uniqueClients: function() {
				let clientIdArray = this.opportunities.filter((o) => o.clientId).map((o) => o.clientId);
				clientIdArray = Array.from(new Set(clientIdArray));
				let result = clientIdArray.map((c) => this.$store.getters.getClientById(c));
				result = result.filter((r) => r);
				result.sort((a, b) => a.name.localeCompare(b.name));
				return result;
			},

			unknownStage: function() {
				return {
					id: 'unknown',
					label: '[Unknown status]',
					hexColor: '#000000',
				};
			},

			stageSummaries: function() {
				let oppList = JSON.parse(JSON.stringify(this.filteredOpportunities));

				let stageMap = new Map();
				let unknownKey = 'unknown';

				stageMap.set(unknownKey, {
					id: unknownKey,
					label: '[Unknown status]',
					hexColor: '#ffffff',
					rgb: chroma('#ffffff').rgb(),
					opportunities: [],
					index: -1,
					totalValue: 0,
				});

				this.pipelineStages.stages.forEach((s, index) => {
					stageMap.set(s.id, {
						id: s.id,
						label: s.label,
						hexColor: s.hexColor,
						rgb: chroma(s.hexColor).rgb(),
						opportunities: [],
						index: index,
						totalValue: 0,
					});
				});

				oppList.forEach((o) => {
					if (stageMap.has(o.statusId)) {
						stageMap.get(o.statusId).opportunities.push(o);
					} else {
						stageMap.get(unknownKey).opportunities.push(0);
					}
				});

				let result = Array.from(stageMap.values());
				result = result.filter((r) => r.opportunities.length);

				result.sort((a, b) => a.index - b.index);

				let all = {
					id: null,
					label: 'All',
					hexColor: '#267AE1',
					rgb: chroma('#267AE1').rgb(),
					index: -1,
					totalValue: 0,
					opportunityCount: 0,
				};

				result.forEach((r) => {
					r.totalValue = r.opportunities.reduce((sum, opportunity) => {
						if (opportunity.timePeriod === 'OneTime') {
							return sum + opportunity.value;
						} else {
							return sum + opportunity.value * opportunity.periods;
						}
					}, 0);
					r.opportunityCount = r.opportunities.length;
					all.totalValue += r.totalValue;
					all.opportunityCount += r.opportunityCount;
					delete r.opportunities;
				});

				result.unshift(all);
				return result.filter((r) => r.opportunityCount);
			},

			totalPages() {
				return Math.ceil(this.filteredOpportunities.length / this.pageSize);
			},

			paginatedItems() {
				const start = (this.currentPage - 1) * this.pageSize;
				const end = start + this.pageSize;
				return this.filteredOpportunities.slice(start, end);
			},

			filteredOpportunities: function() {
				let result = [...this.opportunities];
				let closed = ['ClosedWon', 'Complete'];

				result.forEach((o) => {
					if (o.clientId) {
						o.client = this.$store.getters.getClientById(o.clientId);
					}
					o.stage = this.pipelineStages.stages.find((s) => s.id === o.statusId);
					if (!o.stage) {
						o.stage = this.unknownStage;
					}
					o.timePeriodDetail = this.timePeriods.find((t) => t.value === o.timePeriod);
					o.sentimentObj = this.confidenceList.find((c) => c.value === o.sentiment);
					o.closed = o.stage ? closed.includes(o.stage.stageType) : false;
					o.totalValue = o.value * o.periods;

					if (o.workflow.length) {
						let wf = [...o.workflow];
						wf.sort((a, b) => b.timestamp.localeCompare(a.timestamp));
						o.lastActivity = wf[0];
					}

					if (o.toDos.length) {
						let td = [...o.toDos.filter((t) => !t.complete && t.dueDate)];
						td.sort((a, b) => a.dueDate.localeCompare(b.dueDate));
						o.nextDue = td[0];
					}
				});

				if (this.filter.sort === 'Stage') {
					if (this.opportunityView === 'CARD') {
						result.sort(this.sortByKanban);
					} else {
						result.sort(this.sortByStatus);
					}
				} else if (this.filter.sort === 'Name') {
					result.sort(this.sortByName);
				} else if (this.filter.sort === 'Value') {
					result.sort(this.sortByValue);
				} else if (this.filter.sort === 'Close date') {
					result.sort(this.sortByCloseDate);
				} else if (this.filter.sort === 'Confidence') {
					result.sort(this.sortByConfidence);
				} else if (this.filter.sort === 'Last activity') {
					result.sort(this.sortByLastActivity);
				} else if (this.filter.sort === 'Next due') {
					result.sort(this.sortByNextDue);
				}

				return result
					.filter(this.isInStageFilter)
					.filter(this.isInSentimentFilter)
					.filter(this.isInValueFilter)
					.filter(this.isInClientFilter)
					.filter(this.isInArchiveFilter)
					.filter(this.isInTextFilter);
			},

			view: function() {
				if (this.$route.path === '/pipeline/opportunities') {
					return 'opportunities';
				} else {
					return 'forecast';
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.stage-overview {
		background-color: rgba(var(--stage-color), 0.3);
		border-radius: 4px;
		padding: 2px 8px;
		font-weight: 600;
		min-height: 40px;
	}

	.stage {
		font-size: 16px;
		font-weight: 600;
		width: fit-content;
		padding: 2px 8px;
		border-radius: 8px;
		background-color: var(--stage-hex-color);
	}
</style>
