import { render, staticRenderFns } from "./DeliverableList.vue?vue&type=template&id=2c5f3896&scoped=true&"
import script from "./DeliverableList.vue?vue&type=script&lang=js&"
export * from "./DeliverableList.vue?vue&type=script&lang=js&"
import style0 from "./DeliverableList.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./DeliverableList.vue?vue&type=style&index=1&id=2c5f3896&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c5f3896",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VIcon,VMenu})
