<template>
	<div class="pb-6">
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' }, { label: 'Client management' }]">
			Contacts
		</page-header>

		<div
			class="column-format fill-height">
			<div class="pb-4 row-format align-center flex-wrap gap-3">
				<v-text-field
					outlined
					hide-details
					dense
					v-model="search"
					placeholder="Search..."
					style="max-width: 200px; background-color: var(--v-white-base)"
					color="gray_30"
				>
					<template v-slot:prepend-inner><span class="material-symbols-rounded font-gray_50">search</span></template>
				</v-text-field>

				<div class="ml-auto row-format align-center gap-3">
					<div class="pointer row-format align-center" v-tippy="{ content: 'List' }" @click="setView('LIST')">
						<span
							class="material-symbols-rounded"
							:style="`font-size: 24px; color: var(--v-${currentView === 'LIST' ? 'primary' : 'gray_60'}-base)`"
							>list</span
						>
					</div>
					<div class="pointer row-format align-center" v-tippy="{ content: 'Card' }" @click="setView('CARD')">
						<span
							class="material-symbols-rounded"
							:style="`font-size: 24px; color: var(--v-${currentView === 'CARD' ? 'primary' : 'gray_60'}-base)`"
							>grid_on</span
						>
					</div>
					<v-btn class="super-action" @click="addNew()"><v-icon size="20">add</v-icon> Add contact</v-btn>
				</div>
			</div>
			<div>
				<div class="row-format flex-wrap gap-3" v-if="currentView === 'CARD'">
					<contact-card
						v-for="contact in filteredContacts"
						:key="contact.id"
						:input="contact"
						@edit="openContact(contact)"
					></contact-card>
				</div>
				<div v-else style="background-color: var(--v-white-base); border: 1px solid var(--v-gray_30-base); border-radius: 4px">
					<v-data-table
						:headers="headers"
						:items="filteredContacts"
						:hide-default-footer="true"
						@click:row="openContact($event)"
						class="primary-table"
						:items-per-page="-1"
						style="cursor: pointer;"

					>
						<template v-slot:item.name="{ item }"> {{ item.firstName }} {{ item.lastName }} </template>
						<template v-slot:item.client.name="{ item }">
							<div class="row-format align-center" v-if="item.client">
								<client-avatar :client="item.client" :x-small="true" :disable-click="false"></client-avatar>
								<div>{{ item.client.name }}</div>
							</div>
						</template>
						<template v-slot:no-data>
							<v-btn text @click="addNew">No contact records</v-btn>
						</template>
					</v-data-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ContactService from '@/modules/clients/contacts/ContactService';
	import ContactCard from '@/modules/clients/contacts/ContactCard';
	import ContactEdit from '@/modules/clients/contacts/ContactEdit';
	import PageHeader from '@/components/PageHeader';
	import ClientAvatar from '@/components/ClientAvatar';
	import DateTime from "@/modules/utils/HDateTime";

	export default {
		name: 'ContactList',

		props: [],

		components: { ClientAvatar, ContactCard, PageHeader },

		data: function() {
			return {
				contactService: new ContactService(),
				contacts: [],
				search: null,
				showMenu: false,
				currentView: 'LIST',
			};
		},

		mounted() {
			this.getContactList();
			this.getCurrentView();
			this.$store.state.eventBus.$on(
				this.$store.getters.getMessageChannelPrefix + '.contact-update',
				this.updateContactHandler
			);
			this.$store.state.eventBus.$on(
				this.$store.getters.getMessageChannelPrefix + '.contact-delete',
				this.deleteContactHandler
			);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off(
				this.$store.getters.getMessageChannelPrefix + '.contact-update',
				this.updateContactHandler
			);
			this.$store.state.eventBus.$off(
				this.$store.getters.getMessageChannelPrefix + '.contact-delete',
				this.deleteContactHandler
			);
		},

		methods: {
			updated: function() {
				this.$emit('updated', this.filter);
			},

			setView: function(view) {
				this.currentView = view;
				localStorage.setItem(this.currentViewKey, view);
			},

			getCurrentView: function() {
				let currentView = localStorage.getItem(this.currentViewKey);
				if (currentView) {
					this.currentView = currentView;
				}
			},

			updateContactHandler: function(event) {
				let contact = event.message;
				let ix = this.contacts.findIndex((c) => c.id === contact.id);
				if (ix > -1) {
					this.contacts.splice(ix, 1, contact);
				} else {
					this.contacts.push(contact);
				}
				this.contacts.sort(this.sortList);
			},

			deleteContactHandler: function(event) {
				let deletedContact = event.message;
				let ix = this.contacts.findIndex((contact) => contact.id === deletedContact.id);
				if (ix > -1) {
					this.contacts.splice(ix, 1);
				}
			},

			getContactList: function() {
				this.contactService.getContactList().then((res) => {
					this.contacts.splice(0, this.contacts.length);
					this.contacts.push(...res.data);
					this.contacts.sort(this.sortList);
				});
			},

			sortList: function(a, b) {
				let aSort = this.getSortString(a);
				let bSort = this.getSortString(b);
				return aSort.localeCompare(bSort);
			},

			getSortString: function(contact) {
				if (contact.firstName) {
					return contact.firstName;
				} else if (contact.lastName) {
					return contact.lastName;
				} else if (contact.email) {
					return contact.email;
				} else {
					return '';
				}
			},

			addNew: function() {
				this.$store.state.globalModalController.openModal(ContactEdit, { id: null }).then((res) => {
					if (res) {
						let ix = this.contacts.findIndex((c) => c.id === res.id);
						if (ix > -1) {
							this.contacts.splice(ix, 1, res);
						} else {
							this.contacts.push(res);
						}
						this.contacts.sort(this.sortList);
					}
				});
			},

			openContact: function(contact) {
				this.$store.state.globalModalController.openModal(ContactEdit, { id: contact.id }).then((res) => {
					if (res) {
						let ix = this.contacts.findIndex((c) => c.id === res.id);
						if (res.deleted) {
							this.contacts.splice(ix, 1);
						} else {
							this.contacts.splice(ix, 1, res);
						}
						this.contacts.sort(this.sortList);
					}
				});
			},

			client: function(contact) {
				if (contact.clientId) {
					return this.$store.state.clientCache.find((c) => c.id === contact.clientId);
				} else {
					return null;
				}
			},
		},

		computed: {
			customFields: function(){
				return this.$store.state.contactFields.fields.filter(c => c.showOnCard)
			},

			headers: function() {
				let result = [
					{ text: 'Client', value: 'client.name' },
						{ text: 'First', value: 'firstName' },
					{ text: 'Last', value: 'lastName' },
					{ text: 'Email', value: 'email' },
					{ text: 'Phone', value: 'phone' },
				];

				this.customFields.forEach(c => {
					result.push({
						text: c.name,
						value: 'Custom-' + c.mappingKey,
					});
				})

				return result;
			},

			currentViewKey: function() {
				return (
					'CONTACT_LIST_CURRENT_VIEW_' + this.$store.getters.getAccountId + '_' + this.$store.getters.getLoggedInUserId
				);
			},

			filteredContacts: function() {
				let result;
				if (this.search) {
					result = this.contacts.filter((c) => {
						let search = this.search.toLowerCase();
						let numeric = this.search.replace(/\D/g, '');
						let fullName = c.firstName + ' ' + c.lastName;

						if (fullName.toLowerCase().includes(search)) {
							return true;
						} else if (c.email && c.email.toLowerCase().includes(search)) {
							return true;
						} else if (c.phone && numeric && c.phone.toLowerCase().includes(numeric)) {
							return true;
						}

						let customValues = c.customValues;

						for (let j = 0; j < customValues.length; j++) {
							let cv = customValues[j];
							if (
								cv.value &&
								cv.value
									.toString()
									.toLowerCase()
									.includes(this.search.toLowerCase())
							) {
								return true;
							}
						}

						return false;
					});
				} else {
					result = this.contacts;
				}

				result.forEach((c) => {
					c.client = this.client(c);
					c.customValues.forEach((v) => {
						if(v.type === 'Date') {
							c['Custom-' + v.mappingKey] = DateTime.fromISO(v.value).toLocaleString(DateTime.DATE_MED)
						}else{
							c['Custom-' + v.mappingKey] = v.value;
						}
					})
				});

				return result;
			},
		},
	};
</script>

<style scoped lang="scss">
	input::placeholder {
		color: var(--v-gray_60-base);
	}
	textarea:focus,
	input:focus {
		outline: 0;
	}
</style>
