var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.initialized)?_c('div',{staticClass:"column-format"},[(!_vm.proposalList.length && !_vm.agreementList.length)?_c('div',{staticClass:"column-format centered fill-height",staticStyle:{"min-height":"calc(100vh - 300px)"}},[_c('empty-view',{attrs:{"header":"No agreements yet","body":"Moxie agreements are beautiful and easy to use.  They provide integrated contracts, e-signatures, payments, and deliverables.","cta":"Create an agreement","video-header":"See how it works","video-body":"Learn how to create proposals, quotes, and contracts that keep you safe and your client happy.","video-cta":"Watch the tutorial","video-id":"5iEIkj2r4Gg"},on:{"cta-clicked":function($event){return _vm.addNew()}}})],1):_vm._e(),((_vm.proposalList.length || _vm.agreementList.length) && !_vm.filteredProposalList.length)?_c('div',{staticClass:"mt-6"},[_c('empty-filtered-results')],1):_vm._e(),_c('div',[_vm._t("filter"),(_vm.filteredProposalList.length)?_c('v-data-table',{staticClass:"pointer mt-4",attrs:{"items":_vm.filteredProposalList,"headers":_vm.headers,"hide-default-footer":true,"items-per-page":-1},on:{"click:row":_vm.openAgreement},scopedSlots:_vm._u([{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"row-format align-center"},[_c('client-avatar',{attrs:{"client":item.client,"small":true}}),_c('div',{staticClass:"ml-n2"},[_vm._v(_vm._s(item.client.name))])],1)]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.DateTime.fromISO(item.date).toLocaleString(_vm.DateTime.DATE_MED))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.formatStatus(item.status))}})]}},{key:"item.version",fn:function(ref){
var item = ref.item;
return [_vm._v(" v"+_vm._s(item.version)+" ")]}},{key:"item.lastEvent",fn:function(ref){
var item = ref.item;
return [(item.lastEvent)?_c('div',[_vm._v(" "+_vm._s(item.lastEvent.event)+" - "+_vm._s(_vm.DateTime.fromISO(item.lastEvent.timestamp).toLocaleString(_vm.DateTime.DATETIME_MED))+" ")]):_c('div',[_vm._v("--")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":"","close-on-click":"","nudge-bottom":30},scopedSlots:_vm._u([{key:"activator",fn:function(scope){return [_c('div',{staticClass:"ml-1"},[_c('v-btn',_vm._g({staticClass:"menu-activator",attrs:{"icon":""}},scope.on),[_c('v-icon',{attrs:{"size":20}},[_vm._v(_vm._s(scope.value ? '$arrowUp' : '$moreHorizontal'))])],1)],1)]}}],null,true)},[_c('div',{staticClass:"more-menu"},[_c('div',{staticClass:"more-menu-item",on:{"click":function($event){return _vm.openAgreement(item)}}},[_vm._v("Edit")]),(item.version === 2 && _vm.client)?_c('div',{staticClass:"more-menu-item",on:{"click":function($event){return _vm.duplicateAgreement(item)}}},[_vm._v(" Duplicate ")]):_vm._e(),_c('div',{staticClass:"more-menu-item",on:{"click":function($event){return _vm.saveAsTemplate(item)}}},[_vm._v("Save as template")]),_c('div',{staticClass:"more-menu-item",on:{"click":function($event){return _vm.confirmDelete(item)}}},[_vm._v("Delete")])])])]}}],null,false,2449233599)}):_vm._e()],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }