<template>
	<div>
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' }]">
			Data import/export
		</page-header>

		<div class="row-format mb-8 gap-3 flex-wrap">
			<div class="page-link"  @click.stop="$router.push('/data/import')">
				<div>Import</div>
			</div>
			<div class="page-link page-link-active">
				<div>Export</div>
			</div>
		</div>

		<v-container class="mt-6" style="max-width: 580px">
			<v-row dense>
				<v-col cols="12" align="center" class="pb-0 mb-0 column-format centered">
					<v-icon color="secondary" large>download</v-icon>
					<div>Download an Excel file which contains Clients, Contacts, Projects, Tasks, Time worked, Invoices, Expenses, and Payments</div>
				</v-col>
			</v-row>
			<v-row v-if="$store.getters.isAccountOwner">
				<v-col cols="12" align="center" class="pt-3">
					<v-btn color="secondary" @click="exportWorkspace()">Export workspace data</v-btn>
				</v-col>
			</v-row>
			<v-row v-else>
				<v-col cols="12" align="center" class="pt-3">
					<div class="font-italic font-gray_70">This feature is only accessible to the account owner</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import ExportService from "@/modules/account/export/ExportService";
import PageHeader from "@/components/PageHeader";

export default {
	name: "Export",

	props: [],

	components: {PageHeader},

	data: function () {
		return {
			exportService: new ExportService(),
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		exportWorkspace: function(){
			this.$store.commit('startLoading');
			this.exportService.exportWorkspace()
					.then((res) => {
						console.log(res);
						let fileURL = window.URL.createObjectURL(res.data);
						let fileLink = document.createElement('a');
						fileLink.href = fileURL;
						fileLink.setAttribute(
								'download',
								'Workspace-Export.xlsx'
						);
						document.body.appendChild(fileLink);
						fileLink.click();
					})
					.finally(() => this.$store.commit('stopLoading'));
		}
	},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>