var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isReady)?_c('div',{staticClass:"column-format gap-6",staticStyle:{"min-height":"40px","height":"100%","position":"relative"},style:(("" + _vm.wrapperStyle)),attrs:{"id":("service-package-" + (_vm.servicePackage.id))},on:{"click":_vm.handleClick}},[(_vm.active && _vm.editMode)?_c('editor',{ref:"textEditor",attrs:{"api-key":_vm.$store.getters.getTinyMceKey,"initial-value":_vm.servicePackage.description,"inline":true,"init":_vm.mceConfigText,"spellcheck":true},on:{"input":_vm.handleTextInput}}):_c('div',{domProps:{"innerHTML":_vm._s(_vm.tokenizedText)}}),(_vm.items.length)?_c('div',{staticClass:"column-format",style:(("border: 1px solid " + (_vm.services.tableBorderColor) + "; border-radius: 4px;"))},[_c('draggable',{attrs:{"disabled":!_vm.editMode || !_vm.active},model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}},_vm._l((_vm.items),function(item,index){return _c('div',{key:item.id,staticClass:"row-format align-start pa-2",style:(("" + (index < _vm.items.length - 1 ? 'border-bottom: 1px solid' + _vm.services.tableBorderColor : '')))},[_c('service-item-renderer',{staticClass:"flex-grow-1",attrs:{"item":item,"tokens":_vm.tokensForItem(item)}}),(_vm.editMode && _vm.active)?_c('v-icon',{staticClass:"pl-2 mt-1 ml-auto material-symbols-rounded pointer",attrs:{"size":"18","color":"gray_70"},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item, $event)}}},[_vm._v("edit")]):_vm._e()],1)}),0)],1):_vm._e(),(_vm.active && _vm.editMode)?_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"font-primary pointer"},on),[_vm._v("+ Line item")])]}}],null,false,833037200),model:{value:(_vm.lineItemMenu),callback:function ($$v) {_vm.lineItemMenu=$$v},expression:"lineItemMenu"}},[_c('products',{attrs:{"currency":_vm.agreement.cursor},on:{"select":function($event){_vm.addLineItem($event);
				_vm.lineItemMenu = false;},"close":function($event){_vm.lineItemMenu = false}}})],1):_vm._e(),(_vm.addOns.length)?_c('div',{staticClass:"ml-1"},[_c('draggable',{attrs:{"disabled":!_vm.editMode || !_vm.active},model:{value:(_vm.addOns),callback:function ($$v) {_vm.addOns=$$v},expression:"addOns"}},_vm._l((_vm.addOns),function(addOn){return _c('div',{key:addOn.id,staticClass:"column-format font-14 text-left",attrs:{"id":("item-" + (addOn.id))}},[_c('div',{staticClass:"row-format align-center pr-2"},[_c('v-switch',{staticClass:"flex-grow-1 custom-switch",attrs:{"dark":false,"inset":true,"hide-details":"","dense":"","color":_vm.$store.getters.getCustomBranding.primaryColor,"disabled":_vm.addOnsDisabled},on:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('service-item-renderer',{staticClass:"flex-grow-1",attrs:{"item":addOn,"tokens":_vm.tokensForItem(addOn)}})]},proxy:true}],null,true),model:{value:(addOn.selected),callback:function ($$v) {_vm.$set(addOn, "selected", $$v)},expression:"addOn.selected"}}),(_vm.active && _vm.editMode)?_c('v-icon',{staticClass:"pl-2 material-symbols-rounded pointer",attrs:{"size":"18","color":"gray_70"},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(addOn, $event)}}},[_vm._v("edit")]):_vm._e()],1)])}),0)],1):_vm._e(),(_vm.active && _vm.editMode)?_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
return [_c('div',_vm._g({staticClass:"font-primary pointer"},on),[_vm._v("+ Optional add-on")])]}}],null,false,2205783528),model:{value:(_vm.addOnMenu),callback:function ($$v) {_vm.addOnMenu=$$v},expression:"addOnMenu"}},[_c('products',{attrs:{"currency":_vm.agreement.cursor},on:{"select":function($event){_vm.addLineItem($event, true);
				_vm.addOnMenu = false;},"close":function($event){_vm.addOnMenu = false}}})],1):_vm._e(),(_vm.showButton)?_c('div',{staticClass:"mt-auto",style:(("width:100%; text-align: " + _vm.buttonAlignment))},[_c('v-btn',{staticClass:"elevation-3",style:(_vm.buttonStyle),attrs:{"dark":false,"color":_vm.servicePackage.selected ? _vm.services.buttonColor : '#FFFFFF',"x-small":_vm.button.xSmall,"small":_vm.button.small,"large":_vm.button.large,"x-large":_vm.button.xLarge,"block":_vm.button.block,"disabled":_vm.mainButtonDisabled},on:{"click":function($event){$event.stopPropagation();return _vm.handleSelection.apply(null, arguments)}}},[_c('div',{staticClass:"pa-1 row-format centered",style:(("color: " + _vm.buttonFontColor + "!important"))},[_c('div',{style:(("color: " + _vm.buttonFontColor + "!important"))},[_vm._v(_vm._s(_vm.servicePackage.buttonText))]),(_vm.servicePackage.selected)?_c('span',{staticClass:"material-symbols-rounded",style:(("font-size: 20px; color:#" + _vm.buttonFontColor + "!important"))},[_vm._v("verified")]):_vm._e()])])],1):_vm._e(),(_vm.active && _vm.editMode)?_c('v-icon',{staticClass:"dragHandle",staticStyle:{"position":"absolute","top":"10px","left":"10px","cursor":"grab"},attrs:{"size":"20","color":"gray_70"}},[_vm._v("drag_handle")]):_vm._e(),(_vm.active && _vm.editMode)?_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pointer",staticStyle:{"position":"absolute","top":"10px","right":"10px"},attrs:{"size":"20","color":"gray_50"}},on),[_vm._v("settings")])]}}],null,false,3558731917),model:{value:(_vm.packageMenu),callback:function ($$v) {_vm.packageMenu=$$v},expression:"packageMenu"}},[_c('package-editor',{attrs:{"agreement":_vm.agreement,"services":_vm.services,"service-package":_vm.servicePackage},on:{"close":_vm.handlePackageEditorClose,"delete":function($event){return _vm.$emit('delete', $event)}}})],1):_vm._e(),(_vm.dialog)?_c('v-menu',{attrs:{"max-width":"500","bottom":"","left":"","nudge-bottom":_vm.menuY,"nudge-right":_vm.menuX,"close-on-content-click":false},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"pa-4",staticStyle:{"background-color":"var(--v-white-base)","min-width":"500px","max-width":"500px","width":"500px"}},[_c('service-item-editor',{attrs:{"mce-config":_vm.mceConfig,"agreement":_vm.agreement,"service-package":_vm.servicePackage,"service-item":_vm.item},on:{"delete":function($event){return _vm.deleteItem($event)},"save":function($event){return _vm.saveItem($event)},"close":function($event){_vm.dialog = false}}})],1)]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }