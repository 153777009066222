import { render, staticRenderFns } from "./ProjectCard.vue?vue&type=template&id=7cac40e2&scoped=true&"
import script from "./ProjectCard.vue?vue&type=script&lang=js&"
export * from "./ProjectCard.vue?vue&type=script&lang=js&"
import style0 from "./ProjectCard.vue?vue&type=style&index=0&id=7cac40e2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cac40e2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VIcon,VMenu})
