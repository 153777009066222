var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticStyle:{"cursor":"pointer"},attrs:{"items":_vm.filteredInvoiceTemplates,"fixed-header":true,"headers":_vm.headers,"hide-default-footer":true,"items-per-page":-1,"sort-by":"name"},on:{"click:row":function($event){return _vm.editTemplate($event)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.DateTime.fromISO(item.created).toLocaleString(_vm.DateTime.DATE_MED))+" ")]}},{key:"item.updated",fn:function(ref){
var item = ref.item;
return [(item.updated)?_c('span',[_vm._v(_vm._s(_vm.DateTime.fromISO(item.updated).toLocaleString(_vm.DateTime.DATE_MED)))]):_c('span',[_vm._v("--")])]}},{key:"item.defaultTemplate",fn:function(ref){
var item = ref.item;
return [(item.defaultTemplate)?_c('v-icon',{attrs:{"small":""}},[_vm._v("check")]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }