import ExpenseService from '@/modules/accounting/expenses/ExpenseService';
import ClientService from '@/modules/clients/ClientService';
import ProjectService from '@/modules/projects/ProjectService';
import VendorService from '@/modules/accounting/vendors/VendorService';

export default {
	data: function() {
		return {
			refreshKey: 0,
			expenses: [],
			clients: [],
			projects: [],
			vendors: [],
			expenseService: new ExpenseService(),
			clientService: new ClientService(),
			projectService: new ProjectService(),
			vendorService: new VendorService(),
			expenseToEdit: null,
			expenseDetailDialog: false,
			recurringExpenseDetailDialog: false,
			recurringExpenseId: null,
			isReady: false,
			totalExpenseCount: 0,
		};
	},

	methods: {
		getExpenses: function(earliest = null, latest = null) {
			//this.isReady = false;
			this.expenseService.getExpenses(earliest, latest).then((res) => {
				this.expenses.splice(0, this.expenses.length);
				this.expenses.push(...res.data);
				if (this.expenses.length === 0) {
					this.getTotalExpenseCount();
				}
			}).finally(() => this.isReady = true);
		},

		getTotalExpenseCount: function() {
			this.expenseService.getExpenseCount().then((res) => {
				this.totalExpenseCount = res.data;
			});
		},

		getClients: function() {
			this.clientService.getClientList().then((res) => {
				this.clients.splice(0, this.clients.length);
				this.clients.push(...res.data);
			});
		},

		getProjects: function() {
			this.projectService.getAllActiveProjects().then((res) => {
				this.projects.splice(0, this.projects.length);
				this.projects.push(...res.data);
			});
		},

		getVendors: function() {
			this.vendorService.getVendors().then((res) => {
				this.vendors.splice(0, this.vendors.length);
				this.vendors.push(...res.data);
				this.vendors.sort((a, b) => (a.name > b.name) ? 1 : -1)
			});
		},

		handleCreate: function(expense) {
			this.refreshKey++;
			this.expenses.push(expense);
		},

		handleUpdate: function(expense) {
			this.refreshKey++;
			let ix = this.expenses.findIndex((e) => expense.id === e.id);
			if (ix !== -1) {
				this.expenses.splice(ix, 1, expense);
			} else {
				this.handleCreate(expense);
			}
		},

		handleDelete: function(id) {
			this.refreshKey++;
			let ix = this.expenses.findIndex((e) => id === e.id);
			if (ix !== -1) {
				this.expenses.splice(ix, 1);
			}
		},

		editExpense: function(expense) {
			this.expenseToEdit = expense;
			this.expenseDetailDialog = true;
		},
	},
};
