var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0 pa-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"text-left column-format gap-1",attrs:{"cols":"12"}},[_c('div',[_vm._v("Font")]),_c('font-selector',{staticClass:"mb-2",attrs:{"font":_vm.branding.font},on:{"font-changed":function($event){return _vm.handleFontChange($event)}}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"py-4",attrs:{"cols":"12"}},[_c('hr',{staticStyle:{"border-top":"1px solid var(--v-gray_30-base)","border-bottom":"none"}})])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"text-left column-format gap-1",attrs:{"cols":"12"}},[_c('div',[_vm._v("Favicon "),_c('span',{staticClass:"font-12 font-gray_70"},[_vm._v("(Best format: 32x32 pixel image in ICO or PNG format)")])]),_c('favicon-uploader',{attrs:{"branding":_vm.branding},on:{"updated":function($event){return _vm.$emit('refresh')}}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"py-4",attrs:{"cols":"12"}},[_c('hr',{staticStyle:{"border-top":"1px solid var(--v-gray_30-base)","border-bottom":"none"}})])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"text-left column-format gap-1",attrs:{"cols":"12"}},[_c('div',[_vm._v("Light mode colors & logo")]),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"block":"","elevation":"0","color":_vm.branding.primaryColor}},on),[_vm._v(" Primary color ")])]}}])},[_c('div',{staticStyle:{"background-color":"var(--v-white-base)"}},[_c('v-color-picker',{attrs:{"show-swatches":"","mode":"hexa"},model:{value:(_vm.branding.primaryColor),callback:function ($$v) {_vm.$set(_vm.branding, "primaryColor", $$v)},expression:"branding.primaryColor"}})],1)]),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"block":"","elevation":"0","color":_vm.branding.navColor}},on),[_vm._v("Nav bar color ")])]}}])},[_c('div',{staticStyle:{"background-color":"var(--v-white-base)"}},[_c('v-color-picker',{attrs:{"show-swatches":"","mode":"hexa"},model:{value:(_vm.branding.navColor),callback:function ($$v) {_vm.$set(_vm.branding, "navColor", $$v)},expression:"branding.navColor"}})],1)]),(_vm.account)?_c('div',{staticClass:"mt-3",style:(("border: 1px solid var(--v-gray_30-base); background-color: " + (_vm.branding.navColor) + "; border-radius: 8px"))},[_c('logo-uploader',{attrs:{"account":_vm.account,"light-mode":true},on:{"updated":function($event){return _vm.updated()}}})],1):_vm._e()],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"py-4",attrs:{"cols":"12"}},[_c('hr',{staticStyle:{"border-top":"1px solid var(--v-gray_30-base)","border-bottom":"none"}})])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"text-left column-format gap-1",attrs:{"cols":"12"}},[_c('div',[_vm._v("Dark mode colors & logo")]),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"block":"","elevation":"0","color":_vm.branding.primaryColorDark}},on),[_vm._v(" Dark primary color ")])]}}])},[_c('div',{staticStyle:{"background-color":"var(--v-white-base)"}},[_c('v-color-picker',{attrs:{"show-swatches":"","mode":"hexa"},model:{value:(_vm.branding.primaryColorDark),callback:function ($$v) {_vm.$set(_vm.branding, "primaryColorDark", $$v)},expression:"branding.primaryColorDark"}})],1)]),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"block":"","elevation":"0","color":_vm.branding.navColorDark}},on),[_vm._v("Dark nav bar color ")])]}}])},[_c('div',{staticStyle:{"background-color":"var(--v-white-base)"}},[_c('v-color-picker',{attrs:{"show-swatches":"","mode":"hexa"},model:{value:(_vm.branding.navColorDark),callback:function ($$v) {_vm.$set(_vm.branding, "navColorDark", $$v)},expression:"branding.navColorDark"}})],1)]),(_vm.account)?_c('div',{staticClass:"mt-3",staticStyle:{"border":"1px solid var(--v-gray_30-base)","background-color":"#000","border-radius":"8px"}},[_c('logo-uploader',{attrs:{"account":_vm.account,"light-mode":false},on:{"updated":function($event){return _vm.updated()}}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }