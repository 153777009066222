<template>
	<div>
		<v-sheet v-if="showClientLogos && logo" :height="height" :width="width" :class="'avatar ' + float + (disableClick ? '' : ' pointer')"  color="transparent" @click="routeToClient($event)">
			<img :src="logo" :width="width" style="border-radius: 4px">
		</v-sheet>

		<v-sheet
			v-else
			:class="'avatar ' + float + (disableClick ? '' : ' pointer')"
			:height="height"
			:width="width"
			:color="activeColor"
			@click="routeToClient($event)">
			<div :class="fontClass">{{ initials }}</div>
			<v-icon v-if="arrow === 'down'" size="16" :color="arrowColor">$triangleDown</v-icon>
			<v-icon v-if="arrow === 'up'" size="16" :color="arrowColor">$triangleUp</v-icon>
		</v-sheet>
	</div>
</template>

<script>
	import ClientPalette from '@/modules/clients/ClientPalette';

	export default {
		name: 'ClientAvatar',
		props: {
			client: { type: Object, required: true },
			left: { type: Boolean, required: false, default: false },
			right: { type: Boolean, required: false, default: false },
			inline: { type: Boolean, required: false, default: false },
			large: { type: Boolean, required: false, default: false },
			small: { type: Boolean, required: false, default: false },
			xSmall: { type: Boolean, required: false, default: false },
			micro: { type: Boolean, required: false, default: false },
			arrow: { type: String, required: false, default: '' },
			disableClick: { type: Boolean, required: false, default: false },
			color: { type: String, required: false },
		},

		data() {
			return {
				clientPalette: new ClientPalette(),
				disabled: false,
			};
		},

		mounted() {
		},

		methods: {
			routeToClient: function (event) {
				if (!this.disableClick && this.isFullUser) {
					event.stopPropagation();
					if(event && (event.ctrlKey || event.metaKey)){
						window.open(`/client/${this.client.id}`, '_blank');
					}else {
						this.$router.push(`/client/${this.client.id}`);
					}
				}
			},
		},

		computed: {
			activeColor: function () {
				return this.color ? this.color : this.client.color;
			},
			showClientLogos: function(){
				if(this.$store.state.podAccount){
					return this.$store.state.podAccount.accountPreferences.showClientLogos;
				}else{
					return false;
				}
			},
			logo: function () {
				let cacheClient = this.$store.getters.getClientById(this.client.id);
				if (cacheClient){
					return cacheClient.logo;
				} else {
					return null;
				}
			},
			isFullUser(){
				return this.$store.getters.isAccountFullUser;
			},
			float: {
				get() {
					if (this.left) return 'float-left mr-2';
					if (this.right) return 'float-right ml-2';
					// if (this.inline) return 'd-inline-block';
					return '';
				},
			},
			size: {
				get() {
					if (this.large) return '40';
					if (this.small) return '24';
					if (this.xSmall) return '20';
					if (this.micro) return '18';
					return '32';
				},
			},
			height: {
				get() {
					if (this.large) return '40';
					if (this.small) return '24';
					if (this.xSmall) return '20';
					if (this.micro) return '18';
					return '32';
				},
			},
			width: {
				get() {
					if (this.arrow) {
						if (this.large) return '64';
						return '56';
					}
					if (this.large) return '40';
					if (this.small) return '24';
					if (this.xSmall) return '20';
					if (this.micro) return '18';
					return '32';
				},
			},
			initials() {
				if (!this.client || !this.client.name) return '--';
				if(this.client.initials) return this.client.initials.toUpperCase();
				const ignoreMe = ['a', 'an', 'as', 'to', 'and', 'of', 'if', 'in'];
				let clientName = this.client.name
					.replace('-', ' ')
					.replace(/[^a-zA-Z0-9 ]/g, '')
					.replace(/ +/g, ' ')
					.trim();
				let words = clientName.toLowerCase().split(' ');
				let ret = [];
				for (var i = 0; i < words.length; i++) {
					if (ignoreMe.indexOf(words[i]) > -1) continue;
					ret[i] = words[i].substring(0, 1);
					if (ret.length == 3) break;
				}
				return ret.join('').toUpperCase();
			},
			arrowColor() {
				if (this.clientPalette.isLightHexColor(this.activeColor)) {
					return 'black';
				} else {
					return 'white';
				}
			},
			fontClass() {
				let cssClasses = [];
				if (this.clientPalette.isLightHexColor(this.activeColor)) {
					cssClasses[0] = 'avatar-text-black';
				} else {
					cssClasses[0] = 'avatar-text-white';
				}

				if (this.large) {
					cssClasses.push('large');
				} else if (this.small) {
					cssClasses.push('small');
				} else if (this.xSmall) {
					cssClasses.push('x-small');
				} else if (this.micro) {
					cssClasses.push('micro');
				} else {
					cssClasses.push('normal');
				}
				return cssClasses.join(' ');
			},
		},
	};
</script>

<style lang="scss" scoped>
	.avatar-text-black {
		color: #000;
	}

	.avatar-text-white {
		color: #fff;
	}

	.avatar {
		border-radius: 4px;
		//border: 1px solid red;
		//text-align: center;
		display: flex;
		align-items: center;
		justify-content: space-between;
		div {
			font-family: Inter, sans-serif;
			letter-spacing: -0.2px;
			font-weight: 500;
			text-align: center;
			&.normal {
				width: 32px;
				line-height: 19px;
				font-size: 12px;
			}
			&.small {
				width: 24px;
				font-size: 10px;
				line-height: 20px;
			}
			&.x-small {
				width: 20px;
				padding-top: 1px;
				font-size: 10px;
				margin-right: 0;
			}
			&.micro {
				width: 18px;
				padding-top: 1px;
				font-size: 8px;
				margin-right: 0;
			}
			&.large {
				width: 48px;
				font-size: 16px !important;
			}
		}
		span {
			margin-right: 2px;
		}
	}
</style>
