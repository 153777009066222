var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-header',{staticClass:"pb-6",attrs:{"bread-crumbs":[
			{ to: '/home', label: 'Home' },
			{ to: '/projects/list', label: 'Project management' } ]}},[_vm._v(" Templates ")]),_c('div',{staticClass:"row-format align-center mb-4"},[_c('v-text-field',{staticStyle:{"max-width":"200px","background-color":"var(--v-white-base)"},attrs:{"outlined":"","hide-details":"","dense":"","placeholder":"Search...","color":"gray_30"},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('span',{staticClass:"material-symbols-rounded font-gray_50"},[_vm._v("search")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"ml-auto super-action",on:{"click":function($event){return _vm.addNew()}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("add")]),_vm._v(" Add template")],1)],1),_c('v-data-table',{staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.filteredPackagedTemplates,"sort-desc":false,"sort-by":"name","items-per-page":-1,"hide-default-footer":true},on:{"click:row":function($event){return _vm.editPackage($event)}},scopedSlots:_vm._u([{key:"item.defaultTimeRelation",fn:function(ref){
		var item = ref.item;
return [_c('v-icon',{attrs:{"small":""}},[_vm._v("$calendarMono")]),_vm._v(" "+_vm._s(_vm.totalTimeline(item.deliverables))+" ")]}},{key:"item.formattedFees",fn:function(ref){
		var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formattedFees(item))}})]}},{key:"item.action",fn:function(ref){
		var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":"","close-on-click":"","nudge-bottom":30},scopedSlots:_vm._u([{key:"activator",fn:function(scope){return [_c('div',{staticClass:"ml-1"},[_c('v-btn',_vm._g({staticClass:"menu-activator",attrs:{"icon":""}},scope.on),[_c('v-icon',{attrs:{"size":20}},[_vm._v(_vm._s(scope.value ? '$arrowUp' : '$moreHorizontal'))])],1)],1)]}}],null,true)},[_c('div',{staticClass:"more-menu"},[_c('div',{staticClass:"more-menu-item",on:{"click":function($event){$event.stopPropagation();return _vm.editPackage(item)}}},[_vm._v("Edit")]),_c('div',{staticClass:"more-menu-item",on:{"click":function($event){$event.stopPropagation();return _vm.duplicatePackage(item)}}},[_vm._v("Duplicate")]),_c('div',{staticClass:"more-menu-item",on:{"click":function($event){$event.stopPropagation();return _vm.deletePackage(item)}}},[_vm._v("Delete")])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }