<template>
	<div id="project-management" v-if="isReady" style="" class="fill-width">
		<div v-if="isBrandNew" class="row-format centered fill-height" style="height: calc(var(--vh) - 400px)">
			<empty-view
				header="You’re in control"
				body="See the big picture of your projects and deadlines, or get into the details of your tasks. "
				cta="Create your first project"
				video-header="See how it works"
				video-body="See how to create projects and tasks, and then update them as your work progresses."
				video-cta="Watch the tutorial"
				video-id="S9WWzr80uDY"
				@cta-clicked="addDeliverable(columns[0])"
			></empty-view>
		</div>

		<div v-else class="fill-width">
			<div id="detail-wrapper" class="align-center">
				<div class="row-format align-center flex-wrap gap-3">
					<div class="row-format align-center gap-3" v-if="currentView !== 'RECURRING'">
						<v-text-field
							outlined
							hide-details
							dense
							v-model="filter.name"
							placeholder="Search..."
							style="max-width: 200px; background-color: var(--v-white-base)"
							color="gray_30"
						>
							<template v-slot:prepend-inner
								><span class="material-symbols-rounded font-gray_50">search</span></template
							>
						</v-text-field>
						<project-filter
							:projects="projects"
							:filter="filter"
							:single-project-mode="isSingleProjectMode"
						></project-filter>
						<project-sort :filter="filter" :single-project-mode="isSingleProjectMode"></project-sort>
						<project-group
							:filter="filter"
							v-if="currentView === 'TABLE'"
							:single-project-mode="isSingleProjectMode"
						></project-group>
					</div>
					<div v-else class="font-14 font-gray_70 brand-medium">Recurring tasks</div>

					<div class="ml-auto row-format align-center gap-3">
						<div class="row-format align-center gap-4 pr-3" style="border-right: 1px solid var(--v-gray_30-base);">
							<div
								class="pointer row-format align-center"
								v-tippy="{ content: 'Import from template' }"
								@click="importIntoProject()"
							>
								<v-icon class="material-symbols-rounded" size="20" color="gray_70">cloud_download</v-icon>
							</div>
						</div>
						<div class="row-format align-center gap-2">
							<div
								class="pointer row-format align-center"
								v-tippy="{ content: 'Recurring tasks' }"
								@click="setView('RECURRING')"
								v-if="project"
							>
								<v-icon size="20" :color="currentView === 'RECURRING' ? 'primary' : 'gray_70'">autorenew</v-icon>
							</div>
							<div class="pointer row-format align-center" v-tippy="{ content: 'Table' }" @click="setView('TABLE')">
								<h-icon
									name="project-table"
									size="20"
									:color="`var(--v-${currentView === 'TABLE' ? 'primary' : 'gray_70'}-base)`"
								></h-icon>
							</div>
							<div class="pointer row-format align-center" v-tippy="{ content: 'Kanban' }" @click="setView('CARD')">
								<h-icon
									name="project-kanban"
									size="20"
									:color="`var(--v-${currentView === 'CARD' ? 'primary' : 'gray_70'}-base)`"
								></h-icon>
							</div>
							<div
								class="pointer row-format align-center"
								v-tippy="{ content: 'Timeline' }"
								@click="setView('GANTT')"
							>
								<h-icon
									name="project-timeline"
									size="20"
									:color="`var(--v-${currentView === 'GANTT' ? 'primary' : 'gray_70'}-base)`"
								></h-icon>
							</div>
							<div
								class="pointer row-format align-center"
								v-tippy="{ content: 'Calendar' }"
								@click="setView('CALENDAR')"
							>
								<v-icon
									class="material-symbols-rounded"
									size="24"
									:color="`var(--v-${currentView === 'CALENDAR' ? 'primary' : 'gray_70'}-base)`"
									>event</v-icon
								>
							</div>
						</div>
						<div v-if="!isSingleProjectMode">
							<v-btn class="super-action" @click="addDeliverable()"><v-icon size="20">add</v-icon> Add task</v-btn>
						</div>
					</div>
				</div>
			</div>

			<div
				:class="`${isSingleProjectMode ? 'body-content-project' : 'body-content'} ${$store.getters.scroll}`"
				id="project-container"
			>
				<recurring-deliverables ref="Recurring" v-if="currentView === 'RECURRING' && project" :project="project">
				</recurring-deliverables>

				<project-kanban
					ref="kanban"
					v-if="currentView === 'CARD'"
					:current-view="currentView"
					:deliverables="deliverablesFiltered"
					:projects="projectsFiltered"
					:update-flag="updateCounter"
					:columns="columns"
					:filter="filter"
					:is-collaborator="isUserCollaborator"
					:header-height="headerHeight"
					:single-project-mode="isSingleProjectMode"
					@add-deliverable="addDeliverable($event)"
					@edit-deliverable="editDeliverable($event)"
					@update-kanban-props="updateKanbanProperties($event)"
				></project-kanban>
				<deliverable-list
					class="pb-6"
					ref="projectList"
					v-if="currentView === 'TABLE'"
					:current-view="currentView"
					:deliverables="deliverablesFiltered"
					:all-projects="projects"
					:projects="projectsFiltered"
					:single-project-mode="isSingleProjectMode"
					:update-flag="updateCounter"
					:columns="columns"
					:filter="filter"
					:is-collaborator="isUserCollaborator"
					:has-projects="hasProjects"
					:header-height="headerHeight"
					@add-deliverable-defaults="addDeliverableDefaults($event)"
					@edit-deliverable="editDeliverable($event)"
					@update-kanban-props="updateKanbanProperties($event)"
					@add-project-deliverable="addDeliverableForProject($event)"
					@edit-project="editProject($event)"
					@confirm-delete="confirmDeleteDeliverable($event, true)"
				></deliverable-list>
				<gantt-view
					v-if="currentView === 'GANTT'"
					:visible="currentView === 'GANTT'"
					:header-height="headerHeight"
					:deliverables="deliverablesFiltered"
					@edit-deliverable="editDeliverable($event)"
					@dates-updated="datesUpdated($event)"
				>
				</gantt-view>
				<project-calendar
					v-if="currentView === 'CALENDAR'"
					:projects="projectsFiltered"
					:deliverables="deliverablesFiltered"
					@edit-deliverable="editDeliverable($event)"
					@update-due-date="updateDueDate($event)"
					:weekends="true"
				></project-calendar>
			</div>
		</div>
	</div>
</template>

<script>
	import ProjectKanban from './ProjectKanban';
	import DeliverableList from './DeliverableList';
	//import ProjectSelector from '../ProjectSelector';
	import EmptyView from '@/components/EmptyView';
	import DeliverableDetail from '@/modules/projects/deliverable/DeliverableDetail';

	import moment from 'moment';
	import ProjectDataMixin from '@/modules/projects/ProjectDataMixin';
	import GanttView from '@/modules/projects/management/GanttView';
	import ProjectFilter from '@/modules/projects/management/ProjectFilter';
	import ProjectSort from '@/modules/projects/management/ProjectSort';
	import ProjectGroup from '@/modules/projects/management/ProjectGroup';
	import RecurringDeliverables from '@/modules/projects/management/RecurringDeliverables';
	import ProjectDeliverableService from '@/modules/projects/deliverable/ProjectDeliverableService';
	import ConfirmModal from '@/components/ConfirmModal';
	import DeliverableImportModal from '@/modules/templates/deliverables/DeliverableImportModal';
	import ProjectCalendar from '@/modules/projects/management/ProjectCalendar';

	export default {
		name: 'ProjectManagement',

		props: ['project'],

		components: {
			ProjectCalendar,
			RecurringDeliverables,
			ProjectSort,
			ProjectFilter,
			ProjectGroup,
			GanttView,
			DeliverableList,
			ProjectKanban,
			EmptyView,
		},

		mixins: [ProjectDataMixin],

		data: function() {
			return {
				currentView: 'TABLE',
				viewKey: 'PROJECT_VIEW',
				isReady: false,
				updateCounter: 0,
				newDeliverableStatus: null,
				newDeliverableDueDate: null,
				weekends: true,
				filter: null,
				channelName: null,
				projectCount: null,
				forceProject: false,
				showAllTasks: false,
				headerHeight: null,
				showMenu: false,
			};
		},

		mounted() {
			this.handleInitialization();
			this.$store.state.eventBus.$on('account-changed', this.accountChanged);
			this.$store.state.eventBus.$on('initialize-projects', this.fetchDeliverables);
			localStorage.setItem('PROJECT_VIEW', 'tasks');
		},

		beforeDestroy() {
			this.handleShutdown();
			this.$store.state.eventBus.$off('account-changed', this.accountChanged);
			this.$store.state.eventBus.$off('initialize-projects', this.fetchDeliverables);
		},

		methods: {
			updated: function() {
				this.$emit('updated', this.filter);
			},

			setView: function(view) {
				if (this.currentView !== view) {
					this.$store.commit('startLoading');
					setTimeout(() => (this.currentView = view), 50);
					localStorage.setItem(this.currentViewKey, view);
				}
			},

			viewSelectorClass(view) {
				if (this.currentView === 'EMPTY') return 'view-selector selected';
				if (view === this.currentView) return 'view-selector selected';
				return 'view-selector';
			},

			importIntoProject: function() {
				let binding = {
					project: this.project,
				};

				this.$store.state.globalModalController.openModal(DeliverableImportModal, binding).then((res) => {
					console.log(res);
				});
			},

			resetFilter: function() {
				this.filter = {
					name: null,
					projects: [],
					status: [],
					assignedTo: [],
					archivedStatus: [false],
					dueBy: null,
					dueDate1: null,
					dueDate2: null,
					weekends: true,
				};
			},

			accountChanged: function() {
				this.handleShutdown();
				this.handleInitialization();
			},

			handleInitialization: function() {
				this.resetFilter();
				this.getCurrentView();
				this.fetchDeliverables();
				this.getFilterState();
				this.channelName = this.getChannelName();
				this.$store.state.eventBus.$on(this.channelName, this.eventHandler);
			},

			handleShutdown: function() {
				this.projects = [];
				this.deliverables = [];
				this.isReady = false;
				this.$store.state.eventBus.$off(this.channelName, this.eventHandler);
			},

			getCurrentView: function() {
				let currentView = localStorage.getItem(this.currentViewKey);
				if (currentView) {
					this.currentView = currentView;
				}
			},

			getFilterState: function() {
				try {
					let filterState = localStorage.getItem(this.filterStateKey);
					if (!this.$validations.isEmpty(filterState)) {
						this.filter = JSON.parse(filterState);
						this.updateCounter++;
					} else {
						this.resetFilter();
					}
				} catch (err) {
					console.log('Error reading preferences from local storage.');
				}
			},

			setFilterState: function(filter) {
				try {
					localStorage.setItem(this.filterStateKey, JSON.stringify(filter));
				} catch (err) {
					console.log('Error putting preferences into local storage.');
				}
			},

			fetchDeliverables: function() {
				if (this.isSingleProjectMode) {
					this.deliverableService
						.getDeliverablesForAccountByProject(this.project.id, !this.showArchivedTasks)
						.then((res) => {
							this.deliverables.splice(0, this.deliverables.length);
							this.deliverables.push(...res.data);

							this.projects.splice(0, this.projects.length);
							this.projects.push(this.project);
							this.isReady = true;
						});
				} else {
					this.deliverableService
						.getDeliverablesForAccount(!this.showArchivedTasks)
						.then((res) => {
							this.deliverables.splice(0, this.deliverables.length);
							this.deliverables.push(...res.data);
							this.getProjectList();
						})
						.catch((err) => {
							console.log(err);
							this.$store.commit('error', err.response.data.message);
						});
				}
			},

			getProjectList: function() {
				this.projectService
					.getAllActiveProjects()
					.then((res) => {
						this.projects.splice(0, this.projects.length);
						this.projects.push(...res.data);
						this.projects.sort(this.sortByClientName);
						if (this.projects.length == 0) {
							this.projectService.getProjectCount().then((res) => {
								this.projectCount = res.data;
								this.isReady = true;
							});
						} else {
							this.isReady = true;
						}
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			},

			toggleShowAllTasks: function() {
				this.showAllTasks = !this.showAllTasks;
				this.$store.state.eventBus.$emit('kanban-card-show-tasks', this.showAllTasks);
			},

			sortByClientName: function(a, b) {
				if (a.client.name > b.client.name) {
					return 1;
				} else if (a.client.name < b.client.name) {
					return -1;
				} else {
					return 0;
				}
			},

			viewSelectorDisabled(view) {
				if (this.currentView === 'EMPTY') return true;
				if (view === this.currentView) return true;
				return false;
			},

			isSelected(view) {
				if (view === this.currentView) {
					return true;
				} else {
					return false;
				}
			},

			handleAddNew: function(type) {
				if (type === 'DELIVERABLE') {
					this.addDeliverable(this.$store.state.deliverableStatusList.statusList[0]);
				} else {
					this.addDeliverable(this.$store.state.deliverableStatusList.statusList[0], true);
				}
			},

			addNew: function() {
				this.addDeliverable(this.$store.state.deliverableStatusList.statusList[0]);
			},

			addDeliverable: function(column, forceProject = false) {
				if (this.currentView === 'RECURRING') {
					this.$refs.Recurring.addNew();
				} else {
					this.newDeliverableStatus = column;
					this.forceProject = forceProject;
					this.selectProject();
				}
			},

			addDeliverableDefaults: function(defaults) {
				if (defaults.project) {
					this.editDeliverable(defaults);
				} else {
					this.selectProjectAsync().then((res) => {
						if (res) {
							defaults.project = res;
							defaults.client = res.client;
						}
						this.editDeliverable(defaults);
					});
				}
			},

			addDeliverableForDate: function(date) {
				this.newDeliverableDueDate = date;
				this.newDeliverableStatus = this.$store.state.deliverableStatusList.statusList[0];
				this.selectProject();
			},

			selectProjectAsync: function() {
				return new Promise((resolve) => {
					if (this.filter.projects.length === 1) {
						let project = this.projects.find((p) => p.id === this.filter.projects[0]);
						resolve(project);
					} else if (this.project) {
						resolve(this.project);
					} else if (this.$store.getters.isAccountCollaborator) {
						resolve(this.projects[0]);
					} else {
						// let binding = {
						// 	isCollaborator: this.isUserCollaborator,
						// 	skipDeliverable: this.forceProject || this.isBrandNew,
						// 	projectFilter: this.filter.projects
						// };
						//
						// this.$store.state.globalModalController.openModal(ProjectSelector, binding, true, false).then((res) => {
						// 	if(!res){
						// 		this.cancelProjectSelection()
						// 		resolve();
						// 		return;
						// 	}else if(res.type === 'project-created'){
						// 		this.projectCreated(res.project);
						// 	}
						//
						// 	resolve(res.project);
						// }).catch((err) => reject(err));
						resolve(null);
					}
				});
			},

			selectProject: function() {
				this.selectProjectAsync().then((res) => {
					this.projectSelected(res);
				});
			},

			addDeliverableForProject: function(project) {
				let deliverableToEdit = {
					id: null,
					project: project,
					client: project.client,
					defaultStatusId: this.$store.state.deliverableStatusList.statusList[0].id,
				};
				this.editDeliverable(deliverableToEdit);
			},

			cancelProjectSelection: function() {
				this.newDeliverableStatus = null;
			},

			projectCreated: function(project) {
				if (project.isTemplate) {
					this.accountChanged();
					return;
				}

				this.updateCounter++;
				project.deliverables = [];
				this.projects.push(project);
				this.projects.sort(this.sortByClientName);
				this.projectSelectorDialog = false;
			},

			projectSelected: function(project) {
				let deliverableToEdit = {
					id: null,
					project: project,
					client: project ? project.client : null,
					defaultStatusId: this.newDeliverableStatus ? this.newDeliverableStatus.id : this.$store.state.deliverableStatusList.statusList[0].id,
					defaultDueDate: this.newDeliverableDueDate,
				};
				this.editDeliverable(deliverableToEdit);
			},

			editDeliverable: function(deliverable) {
				let defaultAssignedTo = deliverable.defaultAssignedTo;

				if (!defaultAssignedTo && !this.$store.getters.isTeamAccount) {
					defaultAssignedTo = this.$store.getters.getLoggedInUserId;
				}

				let binding = {
					deliverableId: deliverable.id,
					client: deliverable.client,
					project: deliverable.project,
					defaultStatusId: deliverable.defaultStatusId,
					defaultDueDate: deliverable.defaultDueDate,
					defaultAssignedTo: defaultAssignedTo,
				};

				this.$store.state.globalModalController.openModal(DeliverableDetail, binding).then((res) => {
					console.log('deliverable edited.', res);
				});
			},

			confirmDeleteDeliverable: function(deliverable, enableRestore = true) {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to delete this Task?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						new ProjectDeliverableService()
							.deleteDeliverable(deliverable.id, enableRestore)
							.then(() => {
								let ix = this.deliverables.findIndex((d) => d.id === deliverable.id);
								if (ix > -1) {
									this.deliverables.splice(ix, 1);
								}
							})
							.catch((err) => {
								this.$store.commit('error', err.response.data.message);
							});
					}
				});
			},

			datesUpdated: function(dates) {
				let patch = [];
				let ix = this.deliverables.findIndex((d) => d.id === dates.id);
				let deliverable = this.deliverables[ix];

				deliverable.startDate = dates.startDate;
				deliverable.dueDate = dates.dueDate;
				this.deliverables.splice(ix, 1, deliverable);

				patch.push({
					op: 'replace',
					path: '/dueDate',
					value: dates.dueDate,
				});

				patch.push({
					op: 'replace',
					path: '/startDate',
					value: dates.startDate,
				});

				this.deliverableService.patchDeliverable(deliverable.id, patch);
			},

			updateDueDate: function(deliverable) {
				let patch = [];

				patch.push({
					op: 'replace',
					path: '/dueDate',
					value: deliverable.dueDate,
				});

				this.deliverableService.patchDeliverable(deliverable.id, patch);
			},

			updateKanbanProperties: function(updates) {
				this.deliverableService.updateKanbanProperties(updates);
			},

			getChannelName() {
				return this.$store.getters.getMessageChannelPrefix + '.pr';
			},

			eventHandler: function(event) {
				if (event.userMetadata === 'ProjectDeliverableMini') {
					this.processDeliverableDataEvent(event.message);
				} else if (event.userMetadata === 'KanbanUpdate') {
					this.processKanbanUpdate(event.message);
				} else if (event.userMetadata === 'Comment') {
					this.processCommentUpdate(event);
				}
			},

			processCommentUpdate: function(event) {
				let deliverableId = event.channel.split('.')[2].split('-')[1];
				let ix = this.deliverables.findIndex((d) => d.id === deliverableId);

				if (ix > -1) {
					let deliverable = this.deliverables[ix];
					let comment = event.message;
					let commentIx = deliverable.comments.findIndex((c) => c.id === comment.id);

					if (comment.comment === '_deleted_') {
						if (commentIx > -1) {
							deliverable.comments.splice(commentIx, 1);
						}
					} else if (commentIx > -1) {
						deliverable.comments.splice(commentIx, 1, comment);
					} else {
						deliverable.comments.push(comment);
					}

					this.deliverables.splice(ix, 1, deliverable);
				}
			},

			processKanbanUpdate: function(updates) {
				for (let i = 0; i < updates.length; i++) {
					let deliverableId = updates[i].deliverableId;
					let kanbanSort = updates[i].kanbanSort;
					let statusId = updates[i].statusId;

					for (let n = 0; n < this.deliverables.length; n++) {
						if (deliverableId === this.deliverables[n].id) {
							this.deliverables[n].kanbanSort = kanbanSort;
							this.deliverables[n].statusId = statusId;
							break;
						}
					}
				}

				this.updateCounter++;
			},

			processDeliverableDataEvent: function(deliverable) {
				let projectIx = this.projects.findIndex((p) => p.id === deliverable.projectId);
				let ix = this.deliverables.findIndex((d) => d.id === deliverable.id);

				if (projectIx === -1 && this.project) {
					if (ix > -1) {
						//deliverable is no longer in scope of this single project view
						this.deliverables.splice(ix, 1);
					}
					return;
				}

				if (ix > -1) {
					if (deliverable.statusId === '__deleted__') {
						this.deliverables.splice(ix, 1);
					} else {
						this.deliverables.splice(ix, 1, deliverable);
					}
				} else if (deliverable.statusId !== '__deleted__') {
					this.deliverables.push(deliverable);
				}
			},

			isInProjectFilter: function(projectId) {
				if (!this.filter.projects || this.filter.projects.length === 0) {
					return true;
				} else if (this.filter.projects.indexOf(projectId) > -1) {
					return true;
				} else {
					return false;
				}
			},

			isInStatusFilter: function(statusId) {
				if (!this.filter.status || this.filter.status.length === 0) {
					return true;
				} else if (this.filter.status.indexOf(statusId) > -1) {
					return true;
				} else {
					return false;
				}
			},

			isInArchivedFilter: function(archived) {
				if (!this.filter.archiveStatus || this.filter.archiveStatus.length === 0) {
					return true;
				} else if (this.filter.archiveStatus.indexOf(archived) > -1) {
					return true;
				} else {
					return false;
				}
			},

			isInAssignedToFilter: function(assignedToList) {
				if (!this.filter.assignedTo || this.filter.assignedTo.length === 0) {
					return true;
				} else if (assignedToList.length == 0 && this.filter.assignedTo.indexOf('unassigned') > -1) {
					return true;
				} else if (this.filter.assignedTo.filter((value) => assignedToList.includes(value)).length > 0) {
					return true;
				} else {
					return false;
				}
			},

			isInNameFilter: function(name, customValues) {
				if (!this.filter.name) {
					return true;
				} else if (name && name.toLowerCase().includes(this.filter.name.toLowerCase())) {
					return true;
				}

				for (let j = 0; j < customValues.length; j++) {
					let cv = customValues[j];
					if (
						cv.value &&
						cv.value
							.toString()
							.toLowerCase()
							.includes(this.filter.name.toLowerCase())
					) {
						return true;
					}
				}

				return false;
			},

			isInDateFilter: function(dueDate) {
				if (!this.filter.dueBy || !this.filter.dueBy.value) {
					return true;
				}

				let dates = null;

				switch (this.filter.dueBy.value) {
					case 'today':
						dates = this.today;
						break;
					case 'this-week':
						dates = this.thisWeek;
						break;
					case 'next-week':
						dates = this.nextWeek;
						break;
					case 'this-month':
						dates = this.thisMonth;
						break;
					case 'next-month':
						dates = this.nextMonth;
						break;
					case 'between':
						dates = {
							start: moment(this.filter.dueDate1),
							end: moment(this.filter.dueDate2),
						};
						break;
					case 'before':
					case 'after':
						dates = {
							start: moment(this.filter.dueDate1),
							end: null,
						};
						break;
				}

				//todo add a check for today

				if (!dueDate && dates.start.isValid()) {
					return false;
				} else {
					let targetDate = moment(dueDate);
					if (this.filter.dueBy.value === 'today' && dates.start.isSame(targetDate)) {
						return true;
					} else if (dates.start && dates.end && dates.start.isValid() && dates.end.isValid()) {
						return targetDate.isBetween(dates.start, dates.end);
					} else if (this.filter.dueBy.value === 'before' && dates.start.isValid()) {
						return targetDate.isBefore(dates.start);
					} else if (dates.start.isValid()) {
						return targetDate.isAfter(dates.start);
					} else {
						return true;
					}
				}
			},

			runSort: function(functions, a, b) {
				for (let i = 0; i < functions.length; i++) {
					let result = functions[i].call(this, a, b);
					if (result !== 0) {
						return result;
					}
				}

				return 0;
			},

			_sortByStartDate: function(a, b) {
				return this.runSort([this.sortByStartDate, this.sortByStatus, this.sortByName], a, b);
			},

			_sortByDueDate: function(a, b) {
				return this.runSort([this.sortByDueDate, this.sortByStatus, this.sortByName], a, b);
			},

			_sortByStatus: function(a, b) {
				return this.runSort([this.sortByStatus, this.sortByDueDate, this.sortByName], a, b);
			},

			_sortByName: function(a, b) {
				return this.runSort([this.sortByName, this.sortByStatus, this.sortByDueDate], a, b);
			},

			_sortByClient: function(a, b) {
				return this.runSort([this.sortByClient, this.sortByProject, this.sortByStatus, this.sortByDueDate], a, b);
			},

			_sortByProject: function(a, b) {
				return this.runSort([this.sortByProject, this.sortByStatus, this.sortByDueDate], a, b);
			},

			_sortByAssigned: function(a, b) {
				return this.runSort([this.sortByAssigned, this.sortByStatus, this.sortByDueDate], a, b);
			},

			_sortByPriority: function(a, b) {
				return this.runSort([this.sortByPriority, this.sortByStatus, this.sortByDueDate], a, b);
			},

			_sortByKanban: function(a, b) {
				return this.runSort([this.sortByKanban, this.sortByStatus, this.sortByDueDate], a, b);
			},
		},

		computed: {
			showArchivedTasks: function() {
				return this.filter && this.filter.archiveStatus && this.filter.archiveStatus.indexOf(true) > -1;
			},

			isSingleProjectMode: function() {
				return !!this.project;
			},

			activeClientIds: function() {
				return this.$store.getters.clients.map((c) => c.id);
			},

			pages: function() {
				let pages = [];
				pages.push({ label: 'Deliverable list', value: 'TABLE' });
				pages.push({ label: 'Kanban', value: 'CARD' });
				pages.push({ label: 'Timeline', value: 'GANTT' });

				return pages;
			},

			hasProjects: function() {
				return this.projects && this.projects.length > 0;
			},

			isBrandNew: function() {
				return !this.hasProjects && this.projectCount == 0;
			},

			filterStateKey: function() {
				if (this.isSingleProjectMode) {
					return (
						'SINGLE_PROJECT_FILTER_STATE_' +
						this.$store.getters.getAccountId +
						'_' +
						this.$store.getters.getLoggedInUserId
					);
				} else {
					return (
						'PROJECT_FILTER_STATE_' + this.$store.getters.getAccountId + '_' + this.$store.getters.getLoggedInUserId
					);
				}
			},

			currentViewKey: function() {
				if (this.isSingleProjectMode) {
					return (
						'SINGLE_PROJECT_CURRENT_VIEW_' +
						this.$store.getters.getAccountId +
						'_' +
						this.$store.getters.getLoggedInUserId
					);
				} else {
					return (
						'PROJECT_CURRENT_VIEW_' + this.$store.getters.getAccountId + '_' + this.$store.getters.getLoggedInUserId
					);
				}
			},

			isUserCollaborator: function() {
				return this.$store.getters.getUserType === 'COLLABORATOR';
			},

			columns: function() {
				return this.$store.state.deliverableStatusList.statusList;
			},

			today: function() {
				return {
					start: moment().startOf('day'),
					end: moment().endOf('day'),
				};
			},

			thisWeek: function() {
				return {
					start: moment().startOf('week'),
					end: moment().endOf('week'),
				};
			},

			nextWeek: function() {
				return {
					start: moment()
						.add(1, 'w')
						.startOf('week'),
					end: moment()
						.add(1, 'w')
						.endOf('week'),
				};
			},

			thisMonth: function() {
				return {
					start: moment().startOf('month'),
					end: moment().endOf('month'),
				};
			},

			nextMonth: function() {
				return {
					start: moment()
						.add(1, 'M')
						.startOf('month'),
					end: moment()
						.add(1, 'M')
						.endOf('month'),
				};
			},

			projectsFiltered: function() {
				let result = [...this.projects];
				result = result.filter((p) => this.activeClientIds.includes(p.clientId));

				for (let i = result.length - 1; i >= 0; i--) {
					if (!this.isInProjectFilter(result[i].id)) {
						result.splice(i, 1);
						continue;
					}
				}

				return result;
			},

			deliverablesFiltered: function() {
				if (this.updateCounter) {
					//do nothing - just for updates;
				}

				let result = [...this.deliverables];
				result = result.filter((d) => !d.client || this.activeClientIds.includes(d.client.id));

				for (let i = result.length - 1; i >= 0; i--) {
					let projectId = result[i].project ? result[i].project.id : null;
					let clientId = result[i].client ? result[i].client.id : null;
					let filterKey = clientId + '-' + projectId;

					if (!this.isInProjectFilter(filterKey)) {
						result.splice(i, 1);
						continue;
					} else if (!this.isInStatusFilter(result[i].statusId)) {
						result.splice(i, 1);
						continue;
					} else if (!this.isInAssignedToFilter(result[i].assignedToList)) {
						result.splice(i, 1);
						continue;
					} else if (!this.isInNameFilter(result[i].name, result[i].customValues)) {
						result.splice(i, 1);
						continue;
					} else if (!this.isInDateFilter(result[i].dueDate)) {
						result.splice(i, 1);
						continue;
					} else if (!this.isInArchivedFilter(result[i].archived)) {
						result.splice(i, 1);
						continue;
					}
				}

				if (this.filter.sort) {
					if (this.filter.sort === 'Due') {
						result.sort(this._sortByDueDate);
					} else if (this.filter.sort === 'Start') {
						result.sort(this._sortByStartDate);
					} else if (this.filter.sort === 'Name') {
						result.sort(this._sortByName);
					} else if (this.filter.sort === 'Client') {
						result.sort(this._sortByClient);
					} else if (this.filter.sort === 'Project') {
						result.sort(this._sortByProject);
					} else if (this.filter.sort === 'Assigned') {
						result.sort(this._sortByAssigned);
					} else if (this.filter.sort === 'Priority') {
						result.sort(this._sortByPriority);
					} else if (this.filter.sort === 'Kanban') {
						result.sort(this._sortByKanban);
					} else {
						result.sort(this._sortByStatus);
					}
				} else {
					result.sort(this._sortByStatus);
				}

				return result;
			},
		},

		watch: {
			showArchivedTasks: function() {
				this.fetchDeliverables();
			},

			filter: {
				handler: function(val) {
					this.setFilterState(val);
				},
				deep: true,
			},
		},
	};
</script>

<style scoped lang="scss">
	#project-management {
	}

	.body-content {
		height: calc(var(--vh) - 180px);
		max-height: calc(var(--vh) - 180px);
		min-height: calc(var(--vh) - 180px);
		overflow-y: auto;
		width: 100%;
		margin-top: 16px;
	}

	.body-content-project {
		height: calc(var(--vh) - 340px);
		max-height: calc(var(--vh) - 340px);
		min-height: calc(var(--vh) - 340px);
		overflow-y: auto;
		width: 100%;
		margin-top: 16px;
	}

	.nav-icon-selected {
		height: 32px;
		width: 32px;
		opacity: 1;
	}

	.nav-icon {
		height: 24px;
		width: 24px;
		opacity: 0.5;
	}
</style>
