<template>
	<div
		@click="more ? $emit('click', $event) : handleClick($event)"
		:class="
			`${route ? 'pointer' : ''} row-format ${expanded ? 'align-center' : 'align-center'}  mx-2 ${
				active ? 'nav-link-active' : ''
			} nav-link${expanded ? '-expanded' : ''}`
		"
	>
		<div v-if="expanded" style="padding-left: 12px; width: 100%" class="column-format">
			<div class="row-format align-center gap-2 pr-2 hover-link" style="width:100%;">
				<v-icon
					:class="!active ? 'material-symbols-rounded' : 'material-symbols-rounded'"
					:color="active && !showChildren ? 'primary' : 'gray_90'"
					size="18"
					>{{ icon }}</v-icon
				>
				<div :class="`nav-label ${active && !showChildren ? 'active' : ''}`">{{ label }}</div>
				<v-icon class="ml-auto material-symbols-rounded" size="16" color="gray_40" v-if="children.length">{{
					showChildren ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
				}}</v-icon>
				<v-icon class="ml-auto material-symbols-rounded" size="16" color="gray_40" v-if="more">chevron_right</v-icon>
			</div>
			<div
				v-if="children.length && showChildren"
				class="text-left font-gray_90 column-format pt-1 mt-2"
				style="font-size: 13px; padding-left: 18px; margin-left: 8px; border-left: 1px solid var(--v-gray_30-base)"
			>
				<div
					:class="`${index < (children.length - 1) ? 'pb-2' : ''} child-label ${child.uri === path ? 'active' : ''}`"
					@click.stop="childClick($event, child)"
					v-for="(child,index) in children"
					:key="child.uri" class="row-format align-center gap-2"
				>
					<client-avatar v-if="child.client" :client="child.client" :micro="true"/><div>{{ child.label }}</div>
				</div>
			</div>
		</div>
		<div
			v-else
			class="row-format centered"
			style="padding-left: 12px;"
			:content="label"
			v-tippy="{ placement: 'right', arrow: true, arrowType: 'round', animation: 'fade', delay: 50, boundary: 'window' }"
		>
			<v-icon
				:class="!active ? 'material-symbols-rounded' : 'material-symbols-rounded'"
				:color="active ? 'primary' : 'gray_90'"
				size="18"
				>{{ icon }}</v-icon
			>
		</div>
	</div>
</template>

<script>
	//import HIcon3 from '@/components/HIcon3';
	import ClientAvatar from "@/components/ClientAvatar";
	export default {
		name: 'NavLink',

		props: ['label', 'icon', 'route', 'disableActive', 'altRoutes', 'navConfig', 'more', 'expanded'],

		components: {ClientAvatar},

		data: function() {
			return {
				showChildren: false,
			};
		},

		mounted() {
			let show = localStorage.getItem('/NAV/CHILDREN/' + this.route);
			this.showChildren = ("true" === show);
		},

		beforeDestroy() {},

		methods: {
			saveShowChildren(){
				localStorage.setItem('/NAV/CHILDREN/' + this.route,this.showChildren.toString());
			},

			childClick: function(event, child) {
				if (event && (event.ctrlKey || event.metaKey)) {
					let w = window.open(child.uri, '_blank');
					w.focus();
				} else {
					this.$router.push(child.uri);
				}
			},

			handleClick: function(event) {
				if (this.children.length) {
					this.showChildren = !this.showChildren;
					this.saveShowChildren();
					return;
				}else if(this.isFavorites){
					return;
				}

				if (event && (event.ctrlKey || event.metaKey)) {
					let w = window.open(this.route, '_blank');
					w.focus();
				} else {
					this.$router.push(this.route);
				}
			},
		},

		computed: {
			isFavorites: function(){
				return this.route === '/favorites';
			},

			favoriteClients: function(){
				return this.$store.state.favoriteClients;
			},

			path: function() {
				return this.$route.path;
			},

			children: function() {
				if(this.isFavorites){
					let result = [];
					this.favoriteClients.forEach(c => {
						let client = this.$store.getters.getClientById(c);
						if(client){
							result.push({
								label: client.name,
								uri:  `/client/${c}`,
								client: client
							});
						}
					})
					result.sort((a,b) => a.label.localeCompare(b.label));
					return result;
				}else {
					if (this.navConfig) {
						let nav = this.navConfig[this.route];
						if (nav) {
							return nav.tabs;
						} else {
							return [];
						}
					} else {
						return [];
					}
				}
			},

			active: function() {
				if (this.disableActive) {
					return false;
				}
				let active = this.$route.path.startsWith(this.route);
				if (active) {
					return true;
				}

				for(let i=0; i < this.children.length; i++){
					if(this.path === this.children[i].uri){
						return true;
					}
				}

				if (this.altRoutes) {
					for (let i = 0; i < this.altRoutes.length; i++) {
						if (this.$route.path.startsWith(this.altRoutes[i])) {
							return true;
						}
					}
				}

				return false;
			},
		},
	};
</script>

<style scoped lang="scss">
	.nav-label {
		font-size: 14px;
		line-height: 14px;
		color: var(--v-gray_90-base);
		font-weight: 400;
		&:hover {
			color: var(--v-primary-base);
		}
	}

	.child-label {
		color: var(--v-gray_90-base);
		&:hover {
			color: var(--v-primary-base);
		}
	}

	.active {
		color: var(--v-primary-base) !important;
		border: none;
		font-weight: 500;
	}

	.nav-link-active {
		background-color: var(--v-white-base);
	}

	.nav-link {
		width: 44px;
		border-radius: 4px;
	}

	.nav-link-expanded {
		width: 219px;
		border-radius: 4px;
	}
</style>
