<template>
	<div class="panel-modal" style="width: 600px">
		<div class="panel-modal-header">
			<div class="row-format align-center">
				<v-icon small class="mr-2 pointer" @click.stop="save(false)">$chevronRight</v-icon>
				<v-icon color="black" class="mr-1 material-symbols-rounded">splitscreen</v-icon>
				<div class="brand-medium font-18"><div>Edit task</div></div>
				<div class="ml-auto">
					<v-btn class="super-action" @click="save(true)">
						{{ $t('global.save') }}
					</v-btn>
					<v-icon size="20" color="gray_60" class="pointer ml-2" @click="confirmDelete()">$delete</v-icon>
				</div>
			</div>
		</div>
		<div class="panel-modal-body text-left">
			<div class="column-format pt-4 pl-3">
				<div class="row-format">
					<v-text-field
						autofocus
						hide-details
						dense
						outlined
						placeholder="Task name"
						v-model="ourDeliverable.name"
					></v-text-field>
				</div>
				<div class="mt-6 mb-4 row-format align-center gap-3">
					<div
						:class="`page-link ${currentView === 'Overview' ? 'page-link-active' : ''}`"
						@click="setView('Overview')"
					>
						<div>Overview</div>
					</div>
					<div
						:class="`page-link ${currentView === 'Recurrence' ? 'page-link-active' : ''}`"
						@click="setView('Recurrence')"
					>
						<div>Recurrence</div>
					</div>
				</div>

				<deliverable-detail
					v-if="currentView === 'Overview'"
					:hide-task-name="true"
					:fee-schedule="deliverablePackage.feeSchedule"
					:deliverable="ourDeliverable"
					:attachment-service="attachmentService"
				></deliverable-detail>

				<div v-else class="column-format gap-3">
					<v-select
						label="Task type"
						hide-details
						outlined
						dense
						persistent-placeholder
						:items="recurringEnable"
						item-value="value"
						item-text="text"
						v-model="ourDeliverable.isRecurring"
					/>
					<div class="column-format gap-3" v-if="ourDeliverable.isRecurring">
						<v-select
							label="Recurring type"
							hide-details
							outlined
							dense
							persistent-placeholder
							:items="recurringOptions"
							item-value="value"
							item-text="text"
							v-model="ourDeliverable.recurringSettings.recurrenceType"
						/>
						<v-select
							v-if="ourDeliverable.recurringSettings.recurrenceType === 'CUSTOM'"
							label="Day of month"
							hide-details
							outlined
							dense
							persistent-placeholder
							:items="daysInMonth"
							item-value="value"
							item-text="text"
							v-model="ourDeliverable.recurringSettings.monthlyDayOfMonth"
						/>
						<div v-if="ourDeliverable.recurringSettings.recurrenceType === 'MONTHLY'" class="row-format gap-3">
							<v-select
									label="Ordinal"
									hide-details
									outlined
									dense
									persistent-placeholder
									:items="weeks"
									item-value="value"
									item-text="text"
									v-model="ourDeliverable.recurringSettings.monthlyWeekOfMonth"
							/>
							<v-select
									label="Day of week"
									hide-details
									outlined
									dense
									persistent-placeholder
									:items="daysInWeek"
									item-value="value"
									item-text="text"
									v-model="ourDeliverable.recurringSettings.monthlyDayOfWeek"
							/>
						</div>
						<div v-if="ourDeliverable.recurringSettings.recurrenceType === 'WEEKLY'" class="column-format text-left pa-2" style="border: 1px solid var(--v-gray_50-base); border-radius: 4px; position: relative">
							<div class="font-12 font-gray_70" style="background-color: var(--v-white-base); position: absolute; top: -9px; padding-left: 2px">Day of week</div>
							<daily-scheduler v-model="ourDeliverable.recurringSettings.weeklyDays" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import DeliverableAttachmentService from '@/modules/templates/deliverables/DeliverableAttachmentService';
	import ConfirmModal from '@/components/ConfirmModal';
	import DeliverableDetail from '@/modules/templates/deliverables/DeliverableDetail';
	import DailyScheduler from "@/modules/recurrence/DailyScheduler";

	export default {
		name: 'DeliverablePackageTaskEdit',
		isRightModal: true,

		props: ['deliverablePackage', 'deliverable'],

		components: { DeliverableDetail, DailyScheduler },

		data: function() {
			return {
				attachmentService: new DeliverableAttachmentService(this.deliverablePackage, this.deliverable),
				ourDeliverable: JSON.parse(JSON.stringify(this.deliverable)),
				currentView: 'Overview',

				timeUnits: [
					{ text: 'Days', value: 'DAYS' },
					{ text: 'Weeks', value: 'WEEKS' },
					{ text: 'Months', value: 'MONTHS' },
				],

				recurringEnable: [
					{ text: 'One time task', value: false },
					{ text: 'Recurring task', value: true },
				],

				recurringOptions: [
					{ text: 'Monthly - specific day of month', value: 'CUSTOM' },
					{ text: 'Monthly - ordinal day of month', value: 'MONTHLY' },
					{ text: 'Daily', value: 'DAILY' },
					{ text: 'Weekly', value: 'WEEKLY' },
					{ text: 'Annually', value: 'ANNUALLY' },
				],

				weeks: [
					{ text: 'First', value: 1 },
					{ text: 'Second', value: 2 },
					{ text: 'Third', value: 3 },
					{ text: 'Fourth', value: 4 },
					{ text: 'Last', value: 5 },
				],

				daysInWeek: [
					{ text: 'Monday', value: 1 },
					{ text: 'Tuesday', value: 2 },
					{ text: 'Wednesday', value: 3 },
					{ text: 'Thursday', value: 4 },
					{ text: 'Friday', value: 5 },
					{ text: 'Saturday', value: 6 },
					{ text: 'Sunday', value: 7 },
				]
			};
		},

		mounted() {
			console.log(this.ourDeliverable);
		},

		beforeDestroy() {},

		methods: {
			setView: function(view) {
				this.currentView = view;
			},

			save: function() {
				this.$emit('result', this.ourDeliverable);
			},

			confirmDelete: function() {
				let binding = {
					headingText: this.$t('templates.packages.delete-deliverable-title'),
					bodyText: this.$t('templates.packages.delete-deliverable-body', { name: this.ourDeliverable.name }),
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.ourDeliverable.files.forEach((f) => {
							this.attachmentService.deleteFile(f.fileName);
						});
						//race condition with GlobalModalController
						//need to defer the result event here
						setTimeout(() => {
							this.ourDeliverable.action = 'DELETED';
							this.$emit('result', this.ourDeliverable);
						}, 100);
					}
				});
			},

			handleDurationKeydown(event) {
				if (event.key === '.') {
					event.preventDefault();
				}
			},

			appendOrdinalSuffix: function(num) {
				const j = num % 10,
					k = num % 100;
				if (j === 1 && k !== 11) {
					return num + 'st';
				}
				if (j === 2 && k !== 12) {
					return num + 'nd';
				}
				if (j === 3 && k !== 13) {
					return num + 'rd';
				}
				return num + 'th';
			},
		},

		computed: {
			daysInMonth: function() {
				let result = [];
				result.push({text: 'Date created', value: 0});
				for (let i = 1; i <= 31; i++) {
					result.push({ text: this.appendOrdinalSuffix(i), value: i });
				}
				return result;
			},

			humanUnits() {
				let i = this.timeUnits.findIndex((u) => {
					return u.value === this.ourDeliverable.dueDate.timeUnit;
				});
				if (i > -1) return this.timeUnits[i].text;
				return '';
			},

			userList: function() {
				let users = [];

				for (let i = 0; i < this.$store.state.usersForAccount.length; i++) {
					let user = this.$store.state.usersForAccount[i];
					users.push(user);
				}
				return users;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
